import { useState } from 'react';
import { Form, Input } from 'antd';
import { CheckCircleIcon } from '@heroicons/react/solid';
import alert from '../../../utils/UseAlert';
import { useTranslation } from 'react-i18next';
import BodyText from '../../common/BodyText';
import Subheading2 from '../../common/Subheading2';
import Modal from '../../common/modals/Modal';
import RoundedButton from '../../common/buttons/RoundedButton';
import { useMutation } from '@tanstack/react-query';
import { updateScheduledLesson } from '../../../data/services/scheduledLessonsServices';
import { ApiError } from '../../../models/Errors';

interface UpdateKlassLinkProps {
  onClickCancel: () => void;
  klassId: number;
  scheduledLesson: number;
  klassLink?: string;
  updateScheduledLessons?: (a: any) => Promise<void>;
}

export default function UpdateKlassLink(props: UpdateKlassLinkProps) {
  const [form] = Form.useForm();
  const [isValidLink, setIsValidLink] = useState(false);
  const { t } = useTranslation('translation', {
    keyPrefix: 'calendar.admin.manageKlass.updateKlassLink',
  });

  async function onFinishChanges() {
    await updateScheduledLesson(props.scheduledLesson, {
      url:
        'https://' +
        form.getFieldValue('klassLink').replace(/^https?:\/\//, ''),
    });
  }

  const { mutate: onFinish, isLoading: isUpdating } = useMutation(
    onFinishChanges,
    {
      async onSuccess() {
        props.updateScheduledLessons &&
          (await props?.updateScheduledLessons(''));
        alert.success(t('success'));
      },
      onError(error: any) {
        const api = new ApiError(error);
        alert.error(api.getErrorMessage());
      },
      onSettled() {
        props.onClickCancel();
      },
    },
  );

  return (
    <Modal visible={true} onClose={props.onClickCancel}>
      <div className="flex flex-col gap-y-6 w-full">
        <Subheading2
          text={t('updateKlassLinkTitle')}
          className="text-primary"
        />
        <BodyText
          text={props.klassLink ?? ''}
          className="text-base-content"
          weight="500"
        />
        <Form name="classroomUrl" form={form} className="w-full">
          <Form.Item>
            <Form.Item id="addLink" name="klassLink" noStyle>
              <Input
                className="rounded-lg bg-base-100 text-base-content"
                onChange={e => {
                  setIsValidLink(e.target.value.includes('meet.google.com'));
                }}
                placeholder={t('placeholder')}
                suffix={
                  <div
                    className={`${
                      isValidLink ? 'text-primary' : 'text-neutral/50'
                    }`}
                  >
                    <CheckCircleIcon className="h-7 w-7" />
                  </div>
                }
              />
            </Form.Item>
          </Form.Item>
        </Form>

        <div className="flex w-full justify-around gap-6">
          <RoundedButton
            text={t('cancel')}
            color="neutral"
            className="w-full"
            onClick={props.onClickCancel}
          />
          <RoundedButton
            text={t('confirm')}
            className="w-full"
            onClick={() => onFinish()}
            loading={isUpdating}
          />
        </div>
      </div>
    </Modal>
  );
}
