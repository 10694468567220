import React, { useCallback } from 'react';
import Layout from '@/components/template/Layout';
import { useEffect, useState } from 'react';
import NoAdminScreenForMobile from './NoAdminScreenForMobile';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';
import { useTranslation } from 'react-i18next';
import { Calendar, DateObject } from 'react-multi-date-picker';
import { Button, Form } from 'antd';
import BodyText from '@/components/common/BodyText';
import i18next from 'i18next';
import {
  CalendarIcon,
  XIcon,
  PencilIcon,
  SaveIcon,
} from '@heroicons/react/outline';
import ModalWarning from '@/components/common/modals/ModalWarning';
import PageTitle from '@/components/common/PageTitle';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import Skeleton from '@/components/common/Skeleton';
import HeadTitle from '@/components/common/HeadTitle';
import { updateUnit } from '@/data/services/unitServices';
import alert from '@/utils/UseAlert';
import useAuth from '@/data/hook/useAuth';
import InfiniteSearchInput from '@/components/common/dataInput/InfiniteSearchInput';
import { getAuthorizedUnits } from '@/utils/getAuthorizedUnits';
import { useQuery } from '@tanstack/react-query';
import { unitsQueryKeys } from '@/data/services/querykeys';

export default function ManageRecessCalendars() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'manageRecessCalendars',
  });
  const { user } = useAuth();
  const mainUserUnitId = user?.unitsIds?.at(0) ?? 0;
  const [form] = Form.useForm();
  const { unitId = mainUserUnitId.toString() } = useParams();
  const [renderEdit, setRenderEdit] = useState<JSX.Element>(<React.Fragment />);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isRenderEdit, setIsRenderEdit] = useState<boolean>(false);
  const [newRecess, setNewRecess] = useState<string[]>();
  const setCurrentStringRecess = useState<string[]>()[1];
  const [recessOldDateObject, setRecessOldDateObject] = useState<DateObject[]>(
    [],
  );

  const [recessCurrentDates] = useState<DateObject[]>([]);
  const [isSaved, setIsSaved] = useState<boolean>();

  const navigate = useNavigate();

  const formatRecessDates = useCallback(
    (values: string[]) => {
      let currentDates: DateObject[] = [];

      values.map(date => {
        return currentDates.push(new DateObject(date.replaceAll('-', ',')));
      });

      form.setFieldsValue({
        recess: currentDates,
      });

      setRecessOldDateObject(currentDates);
    },
    [form],
  );

  const { data: unit, isInitialLoading: isLoading } = useQuery({
    ...unitsQueryKeys.get(Number(unitId)),
    enabled: !isNaN(Number(unitId)),
  });

  useEffect(() => {
    if (recessCurrentDates.length === 0) {
      formatRecessDates(unit?.recess ?? []);
    }
  }, [formatRecessDates, recessCurrentDates.length, unit]);

  useEffect(() => {
    if (isRenderEdit) {
      setRenderEdit(
        <DatePanel className="calendarPanel" header={t('dates')} />,
      );
    } else {
      setRenderEdit(<React.Fragment />);
    }
  }, [isRenderEdit, t]);

  function handleDontSaveDateChange() {
    setIsRenderEdit(false);
    setIsVisible(false);
    form.resetFields();
    form.setFieldsValue({
      recess: recessOldDateObject,
    });
  }

  async function handleSaveDateChange() {
    try {
      const unitIdReq = Number(unitId);
      updateUnit(unitIdReq, {
        recess: newRecess,
      });
      alert.success(t('successMessage'));
    } catch (error: any) {
      alert.error('errorMessage');
    }

    setIsRenderEdit(!isRenderEdit);
    setCurrentStringRecess(newRecess);
    setIsVisible(false);
  }

  function dateChange(values: DateObject | DateObject[] | null) {
    if (Array.isArray(values)) {
      let dates = values?.map((date: DateObject) => {
        return date.format('YYYY-MM-DD');
      });
      setNewRecess(dates);
    }
  }

  const dontSaveModal = () => {
    setIsSaved(false);
    setIsVisible(true);
  };

  const saveModal = () => {
    setIsSaved(true);
    setIsVisible(true);
  };

  return (
    <React.Fragment>
      <HeadTitle routeInfo={t('recessCalendar')} />
      <div className="md:hidden">
        <NoAdminScreenForMobile />
      </div>
      <Layout>
        <div className="hidden md:flex lg:flex-col gap-2">
          {unitId && unit ? (
            <React.Fragment>
              <HeadTitle
                routeInfo={`${t('recess')} ${unit.name.replace('_', ' ')}`}
              />
              <PageTitle
                headingText={`${t('recess')} ${unit.name.replace('_', ' ')}`}
                backButton={true}
                position="mb-0"
              />
            </React.Fragment>
          ) : (
            <ConditionalRenderer
              condition={!isLoading}
              fallback={<Skeleton className="bg-primary/40 h-7 w-[360px]" />}
            >
              <HeadTitle routeInfo={t('recessCalendar')} />

              <PageTitle
                headingText={t('manageRecessCalendars')}
                backButton={true}
                position="mb-0"
              />
            </ConditionalRenderer>
          )}
          <ConditionalRenderer condition={!!unit}>
            <InfiniteSearchInput
              service={unitsQueryKeys.list}
              blockDeselect
              displayName={unit => unit.name}
              selectedItem={unit}
              onSelect={({ id }) => {
                navigate(`/admin/units/${id}/recess-calendar`);
              }}
              filters={{
                idIn: getAuthorizedUnits(user)?.toString(),
                isActive: true,
              }}
              className={{
                input:
                  'w-60 flex self-end rounded-md placeholder:font-400 max-w-2xl',
              }}
              input={{
                testId: 'selectUnit',
              }}
            />
          </ConditionalRenderer>
          <div className="rounded-xl bg-base-100 flex flex-col p-7">
            <div className="flex w-full justify-between">
              <Button
                type="default"
                className="h-9 rounded-full p-0 
                border-0 bg-primary-content flex 
                gap-4 items-center justify-center py-2 px-3.5"
              >
                {
                  <CalendarIcon
                    className="w-6 h-6 text-primary"
                    strokeWidth={1.7}
                  />
                }
                <BodyText
                  text={t('recessCalendar')}
                  className="text-primary"
                  weight="500"
                />
              </Button>
              {!isRenderEdit && (
                <Button
                  type="default"
                  className="h-9 rounded-full p-0 border-0 flex gap-4 items-center 
                  shadow-none justify-center py-2 px-3.5 w-auto"
                  onClick={() => setIsRenderEdit(!isRenderEdit)}
                >
                  <PencilIcon className="w-5 h-5 text-primary" />
                  <BodyText text={t('edit')} className="text-primary" />
                </Button>
              )}

              {isRenderEdit && (
                <div className="flex w-full justify-end items-center gap-x-5">
                  <Button
                    type="default"
                    className="h-7 rounded-lg 
                border-0 flex gap-1.5 items-center shadow-none
                justify-center py-2 px-3.5 bg-neutral-content"
                    onClick={() => dontSaveModal()}
                  >
                    <XIcon className="w-5 h-5 text-base-content" />
                    <BodyText
                      text={t('cancel')}
                      className="text-base-content"
                    />
                  </Button>
                  <Button
                    type="default"
                    className="h-7 rounded-lg  
                border-0 flex gap-1.5 items-center shadow-none
                justify-center py-2 px-3.5 bg-primary"
                    onClick={() => saveModal()}
                  >
                    <SaveIcon className="w-4 h-4 text-base-100" />
                    <BodyText text={t('save')} className="text-base-100" />
                  </Button>
                </div>
              )}
            </div>

            <div>
              <Form
                autoComplete="off"
                form={form}
                name="basic"
                className="flex justify-between w-full p-4"
                initialValues={{ remember: true }}
              >
                <Form.Item name="recess">
                  <Calendar
                    onlyYearPicker={false}
                    format={
                      i18next.language === 'pt_BR' ? 'DD/MM/YYYY' : 'MM/DD/YYYY'
                    }
                    months={moment.months()}
                    weekDays={moment.weekdaysShort()}
                    onChange={value => dateChange(value)}
                    disabled={!isRenderEdit}
                    fullYear
                    multiple
                    className="shadow-none z-0"
                    plugins={[renderEdit]}
                  />
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>

        {isSaved && (
          <ModalWarning
            visible={isVisible}
            translationString={'modalSaveChanges'}
            onClickConfirm={() => handleSaveDateChange()}
            onClickCancel={() => setIsVisible(false)}
          />
        )}

        {!isSaved && (
          <ModalWarning
            visible={isVisible}
            translationString={'modalDiscardChanges'}
            onClickConfirm={() => handleDontSaveDateChange()}
            onClickCancel={() => setIsVisible(false)}
          />
        )}
      </Layout>
    </React.Fragment>
  );
}
