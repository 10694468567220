import { DurationInputArg1, DurationInputArg2 } from 'moment';
import { RequestEvent } from './utils/observers/RequestObserver';

type UpdatesEndtime = [DurationInputArg1, DurationInputArg2];

export const IMAGE_TIMEOUT = 2000;
export const MANAGEMENT_PAGE_SIZE = 10;
export const TIME_RELEASE_LINK_SCHEDULED_LESSON_MS = 30 * 60 * 1000; // 30min
export const REQUEST_STALE_TIME_IN_MS = 20 * 60 * 1000; // 20min;
export const GRADE_CONDITION_FOR_TROPHY = 0.5;
export const MINUTES_OPTIONS = [0, 15, 30, 45];
export const ACTIVE_LESSON_TAB_STORAGE_KEY = 'astro/activelessontab';
export const REQUEST_AUTOSAVE_CHAPTER_TIME_IN_MS = 60 * 1000; // 1min;
export const STAFF_CURRENT_QUESTION = 'staffCurrentQuestion';
export type REWARD_TRIGGER_EVENT = Omit<RequestEvent, 'url'> & { url: RegExp };
export const REWARDS_TRIGGER_EVENTS: REWARD_TRIGGER_EVENT[] = [
  {
    method: 'delete',
    statusCode: 204,
    url: /projects/g,
  },
  {
    method: 'patch',
    statusCode: 200,
    url: /homework-progress/g,
  },
  {
    method: 'post',
    statusCode: 201,
    url: /activity-progress-attempt/g,
  },
  {
    method: 'patch',
    statusCode: 200,
    url: /chapter-progress/g,
  },
  {
    method: 'patch',
    statusCode: 200,
    url: /^activity-progress/,
  },
  {
    method: 'post',
    statusCode: 201,
    url: /shop\/purchase/,
  },
];

export const TOKEN_RESPONSE_KEY = 'token_response_key';

export const USER_SETTINGS = 'user_settings';
export const UPDATES_SETTINGS = 'updates_settings';
export const TRANSFORM_KEYS_PAYLOAD_IGNORE = ['map', 'teacherCode'];
export const HIDDEN_COMPONENTS_SETTINGS = 'hidden_components_settings';
export const UPDATES_ENDTIME: UpdatesEndtime = [3, 'months'];
export const TIME_REFRESH_NOTIFICATIONS = 10000; // 10sec
export const INTERVAL_BETWEEN_SOUND_ALERTS = 20000; //20sec
