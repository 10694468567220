import { AlterDatePill } from '@/components/admin/calendar/ManageScheduledLesson/AlterDatePill';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import Text from '@/components/common/dataDisplay/Text';
import LinkText from '@/components/common/LinkText';
import Skeleton from '@/components/common/Skeleton';
import { LoadingIcon } from '@/components/icons';
import { REQUEST_STALE_TIME_IN_MS } from '@/constants';
import {
  klassesQueryKeys,
  scheduledLessonsQueryKeys,
} from '@/data/services/querykeys';
import { formatLessonName } from '@/functions/lessonsName';
import { ROUTES } from '@/utils/routes';
import { useQuery } from '@tanstack/react-query';
import { DetailTargetProps } from '../../card/NotificationTypes';

const DelayNotificationDetail: React.FC<DetailTargetProps> = ({ targetId }) => {
  const { data: scheduledLesson, isInitialLoading } = useQuery(
    scheduledLessonsQueryKeys.get(targetId),
  );
  const { data: klass, isInitialLoading: isInitialLoadingKlass } = useQuery({
    ...klassesQueryKeys.get(scheduledLesson?.klass || 0),
    staleTime: REQUEST_STALE_TIME_IN_MS,
    enabled: !isNaN(scheduledLesson?.klass || NaN),
  });

  if (isInitialLoading) {
    return (
      <div className="w-full flex justify-center">
        <LoadingIcon className="h-5 w-5 text-primary" />
      </div>
    );
  }

  if (!scheduledLesson) {
    return <Text text="Não foi possivel buscar os detalhes da notificação" />;
  }

  const { lesson, datetime } = scheduledLesson;

  const reportLink = ROUTES.CLASS.LESSON_REPORT(
    klass?.id || 0,
    scheduledLesson.id,
  );

  const klassLink = ROUTES.ADMIN.CLASSES.INFO({
    name: klass?.name.replace('#', '') || '',
  });
  return (
    <div className="manage-scheduled-lesson flex justify-between bg-secondary-content p-1.5 rounded-md border border-secondary/20">
      <div className="flex flex-col gap-0.5">
        <LinkText
          text={formatLessonName(lesson)}
          className="flex w-full font-700 text-14 md:text-16"
          href={reportLink}
        />
        <ConditionalRenderer
          condition={!isInitialLoadingKlass}
          fallback={
            <Skeleton className="bg-secondary/20 h-[15px] w-full rounded-md" />
          }
        >
          <LinkText
            href={klassLink}
            text={klass?.name}
            className="font-rubik !text-12 md:!text-14 leading-none text-primary font-500"
          />
        </ConditionalRenderer>
      </div>
      <AlterDatePill
        dateTime={new Date(datetime)}
        klassDuration={klass?.duration}
      />
    </div>
  );
};

export default DelayNotificationDetail;
