import { twMerge } from 'tailwind-merge';

export const scrollBarDefaultStyle =
  'scrollbar-thumb-primary/40 scrollbar-track-primary-content scrollbar-thin scrollbar-track-rounded-full scrollbar-thumb-rounded-full overflow-visible';

export const scrollBarClassName = twMerge(
  scrollBarDefaultStyle,
  'overflow-auto',
);

export const scrollBarYClassName = twMerge(
  scrollBarDefaultStyle,
  'overflow-y-auto',
);

export const scrollBarXClassName = twMerge(
  scrollBarDefaultStyle,
  'overflow-x-auto',
);
