import { KlassStatusEnum } from '@/models/Klass';
import { ContentGivenEnum, PresenceEnum } from '@/models/ScheduledLessonReport';
import {
  GameErrorCodes,
  GroundToken,
  InteractiveToken,
} from '@cna-br/astro-code';
import TranslateInterface, {
  Sheet,
  TranslateBehaviorAssessmentForm,
  TranslateCommentAssessmentForm,
} from './translateInterface';
import { AlertTypeEnum } from '@/models/Alert';
import { UserTypeEnum } from '@/models/User';

const userStatus = {
  REGISTRED: 'Registrado',
  ACTIVE_ENROLLMENT: 'Matrícula Ativa',
  INACTIVE: 'Inativo',
  WAITING_ENROLLMENT: 'Aguardando Matrícula',
};

const klassStatus: Record<KlassStatusEnum, string> = {
  OPEN: 'Aberta',
  IN_PROGRESS: 'Em progresso',
  CONCLUDED: 'Concluída',
  CANCELED: 'Cancelada',
};

const presenceStatus: Record<PresenceEnum, string> = {
  ATTENDED: 'Presente',
  MISSED: 'Falta',
  NONE: 'Sem Registro',
  RESCHEDULED: 'Reagendado',
};

const sheet: Sheet = {
  title: 'Title',
  comment: 'Comentário',
  assessment: 'Avaliação',
  assessments: {
    noInfo: 'Nenhuma informação adicionada sobre',
    loadMore: 'Carregar mais',
    moreDetails: 'Mais detalhes',
  },
};

const behaviorAssessmentForm: TranslateBehaviorAssessmentForm = {
  successCreatedMessage: 'Avaliação criada com sucesso',
  successUpdatedMessage: 'Avaliação editada com sucesso',
  labels: {
    energy: 'Energia',
    maturity: 'Maturidade',
    speed: 'Velocidade',
    attention: 'Atenção',
    organization: 'Organização',
    initiative: 'Interesse/Iniciativa',
    socialization: 'Socialização',
    apprenticeship: 'Aprendizado',
  },
};

const commentAssessmentForm: TranslateCommentAssessmentForm = {
  successCreatedMessage: 'Comentário criado com sucesso',
  successUpdatedMessage: 'Comentário editado com sucesso',
  commentInput: {
    label: {
      studentLabel: 'Adicionar comentário',
      klassLabel: 'Adicionar observação',
    },
    placeholder: {
      studentCommentAbout:
        'Escreva um comentário sobre {{name}}, as informações adicionadas no histórico serão somente para uso interno e não serão visualizados pelo aluno ou responsável',
      klassCommentAbout:
        'Escreva uma observação sobre a turma {{name}}, as informações adicionadas serão para uso interno e não serão visualizadas pelos alunos ou pelos responsáveis',
    },
  },
  klassInput: {
    placeholder: 'Selecione uma turma',
  },
};

const courseCategory = {
  ONLINE: 'Online',
  F2F: 'Presencial',
  SCHOOL: 'Na escola',
};

const courseType = {
  CTRL_KIDS: 'Ctrl+Kids',
  CTRL_TEENS: 'Ctrl+Teens',
  CTRL_YOUNG: 'Ctrl+Young',
  CTRL_QUICK: 'Ctrl+Quick',
  CTRL_PRO: 'Ctrl+Pro',
};

const weekday = {
  MONDAY: 'Segunda-feira',
  TUESDAY: 'Terça-feira',
  WEDNESDAY: 'Quarta-feira',
  THURSDAY: 'Quinta-feira',
  FRIDAY: 'Sexta-feira',
  SATURDAY: 'Sábado',
  SUNDAY: 'Domingo',
};

const pt_BR: TranslateInterface = {
  errors: {
    serverError:
      'Ocorreu um erro, tente novamente mais tarde ou contate o suporte',
    errorPage: {
      backButton: 'Voltar',
      panelButton: 'Painel',
      reloadButton: 'Recarregar',
      activityNotFound: {
        title: 'Ops! Essa atividade não existe.',
        subtitle:
          'A atividade que você está procurando não foi encontrada, confira se a url está correta',
      },
      badRequest: {
        title: 'Ops! Algo não saiu como esperado',
        subtitle:
          'Vamos corrigir o problema, tente acessar novamente mais tarde.',
      },
      internError: {
        title: 'Ops! Algo deu errado.',
        subtitle:
          'Vamos corrigir o problema, tente acessar novamente mais tarde.',
      },
      notFound: {
        title: 'Ops! Essa página não existe.',
        subtitle:
          'A página que você está procurando não foi encontrada, confira se a url está correta.',
      },
      materialNotFound: {
        title: 'Ops! Esse material não existe.',
        subtitle:
          'O material que você está procurando não foi encontrado, confira se a url está correta',
      },
      forbidden: {
        title: 'Ops! Você não tem permissão para acessar essa página.',
        subtitle:
          'Você não tem permissão para acessar essa página, entre em contato com o administrador do sistema.',
      },
      lessonForbidden: {
        title: 'Ops! Você não tem permissão para acessar essa lição.',
        subtitle:
          'Certifique-se que você está matriculado neste curso ou entre em contato com um administrador',
      },
      reactException: {
        title: 'Ops! É raro mas acontece!',
        subtitle:
          'Tente recarregar a página, caso o erro persista, iremos resolver o mais rápido possível!',
      },
    },
    errorPrefix: 'Corrija os seguintes erros: ',
  },
  common: {
    course: 'Curso',
    lessons: 'Aulas',
    saved: 'Salvo',
    clear: 'Limpar',
    dateRangeInput: {
      selectDate: 'selecione as datas',
      cancelSelect: 'Cancelar seleção',
    },
    copied: 'Copiado',
    copy: 'Copiar',
    collapse: 'Colapsar',
    expand: 'Expandir',
    userNotFound: 'Usuário não encontrado',
    lessonUnpublished: 'Aula não publicada',
    courses: 'Cursos',
    add: 'Adicionar',
    madeBy: '© Feito pela',
    withLove: 'com',
    inBrazil: 'no 🇧🇷',
    userType: {
      [UserTypeEnum.PARENT]: 'Pai',
      [UserTypeEnum.SUPER_ADMIN]: 'Super Administrador',
      [UserTypeEnum.UNIT_ADMIN]: 'Administrador',
      [UserTypeEnum.TEACHER]: 'Professor',
      [UserTypeEnum.STUDENT]: 'Aluno',
    },
    studentAge: {
      sixOrLess: '6 ou menos',
      sevenToNine: '7 a 9',
      tenToThirteen: '10 a 13',
      fourteenToSeveteen: '14 a 17',
    },
    scheduledLessonReportStatus: {
      ACTIVE: 'Relatório ativo',
      CANCELED: 'Relatório cancelado',
      TRANSFERED: 'Relatório transferido',
      NOT_ENROLLED: 'Não ativo nesta aula',
    },
    filter: {
      admin: 'Administrador',
      teacher: 'Professor',
      clearButton: 'Limpar',
      okButton: 'OK',
    },
    months: {
      january: 'Janeiro',
      february: 'Fevereiro',
      march: 'Março',
      april: 'Abril',
      may: 'Maio',
      june: 'Junho',
      july: 'Julho',
      august: 'Agosto',
      september: 'Setembro',
      october: 'Outubro',
      november: 'Novembro',
      december: 'Dezembro',
    },
    day: 'Dia',
    confirm: 'Confirmar',
    cancel: 'Cancelar',
    save: 'Salvar',
    never: 'Nunca',
    generalInformation: 'Informações gerais',
    alterPassword: 'Alterar senha',
    rewards: 'Recompensas',
    lastLogin: 'Último acesso',
    form: {
      required: 'Esse campo é requerido',
    },

    completed: 'Concluído',
    lessonTitle: 'Título da aula',
    edit: 'Editar',
    notEditable: 'Não editável',

    input: {
      unit: {
        label: 'Unidade',
        placeholder: 'Selecione uma unidade',
      },
      teacher: {
        label: 'Professor',
        placeholder: 'Selecione um professor',
      },
    },
    students: 'Alunos',
    info: 'Informações',
    sheet: 'Ficha',
    noResults: 'Sem resultados',
    students_one: '{{count}} aluno',
    students_other: '{{count}} alunos',
    increasingTime: 'Horário crescente',
    descendingTime: 'Horário decrescente',

    accessibility: {
      yourClassShip: 'A nave da sua classe',
      weekdayKlassCell: 'Célula da classe',
      klassPopup: 'Popup da classe',
      todayLessons: 'Aulas de hoje',
      notificationsPage: 'Página de notificações',
    },

    onVacation: 'Em férias',
    notifications: 'Notificações',
  },
  formValidation: {
    user: {
      username: {
        pattern: 'Apenas letras, números e . - _ são aceitos para usuário',
        minLength: 'Usuário deve ter no mínimo 5 caracteres',
        usernameExists: 'Esse nome de usuário já existe',
        startsLetters: 'Usuário só pode começar com letras',
        required: 'Usuário é obrigatório',
      },
    },
  },
  imageInput: {
    chooseImage: 'Escolha uma imagem',
    delete: 'Excluir',
  },
  login: {
    sendEmail: {
      forgotPassword: 'Esqueci minha senha',
      weRecoverPassword: 'Vamos recuperar a sua senha',
      back: 'Voltar',
      emailLabel: 'E-mail',
      emailSent: 'E-mail enviado com sucesso',
      emailPlaceholder: 'Digite seu e-mail',
      usernamePlaceholder: 'Digite o username',
      submit: 'Enviar',
      checkEmail:
        'Cheque seu e-mail com as instruções para finalizar a redefinição de sua senha',
      specifyUser: 'Especifique usuário para alterar a senha',
      userTypeRequired: 'Tipo de usuário não informado',
      usernameRequired: 'Username é obrigatório',
    },
    resetPassword: {
      alreadyRegister: 'Já possui conta?',
      confirmNewPassword: 'Confirme a nova senha',
      confirmRedefine: 'Redefinir senha',
      login: 'Faça login aqui',
      newPassword: 'Nova senha',
      redefinePassword: 'Redefina sua senha',
      typeNewPassword: 'Digite sua nova senha',
      differentPasswordError: 'As senhas inseridas não coincidem',
      modal: {
        canLogin: 'Você já pode fazer o seu login utilizando a senha nova',
        success: 'Senha redefinida com sucesso!',
      },
    },
    motivationalMessage: 'Vamos começar a aprender? 🚀',
    loginAccount: 'Entre com a sua conta',
    login: 'Login',
    loginPlaceholder: 'Digite seu e-mail ou usuário',
    password: 'Senha',
    passwordPlaceholder: 'Digite sua senha',
    keepConnected: 'Mantenha-me conectado',
    signIn: 'Entrar',
    forgotPassword: 'Esqueceu sua Senha?',
  },

  newAccount: {
    questionText: 'Novo por aqui?',
    linkText: 'Crie sua conta grátis',
  },

  tableHeader: {
    search: 'Pesquisar',
    filter: 'Filtrar',
    increasing: 'Crescente',
    decreasing: 'Decrescente',
  },

  modalAddStudent: {
    addStudentPhrase: 'Quais alunos gostaria de adicionar?',
    confirm: 'Confirmar',
    cancel: 'Cancelar',
  },

  modalChangePassword: {
    passwordChangeMessage:
      'Tem certeza que deseja gerar uma nova senha para este aluno?',
    confirm: 'Confirmar',
    cancel: 'Cancelar',

    warning: 'Atenção!',
    lostChangesWarning:
      'Você possui alterações não salvas que serão perdidas se sair da página',
    confirmLeave: 'Tem certeza de que deseja sair?',
    leave: 'Sair',
    stay: 'Permanecer',

    passwordChangeSuccess: 'Nova senha gerada com sucesso!',
    emailSent: 'A nova senha foi enviada ao e-mail do aluno e do responsável',
    close: 'Fechar',
  },

  modalWarning: {
    modalAnswerKey: {
      attention: 'Atenção!',
      warning: 'Se você abrir o gabarito, não poderá refazer mais a atividade',
      proceed: 'Deseja ver o gabarito agora?',
      confirm: 'Gabarito',
      cancel: 'Cancelar',
    },

    modalLeaveActivityPage: {
      attention: 'Atenção!',
      warning: 'Se você sair da atividade o seu progresso não será salvo',
      proceed: 'Tem certeza que deseja sair?',
      confirm: 'Sair',
      cancel: 'Ficar',
    },

    modalSaveChanges: {
      attention: 'Tem certeza que deseja alterar?',
      warning: 'Essa ação irá gravar as informações',
      proceed: '',
      confirm: 'Confirmar',
      cancel: 'Cancelar',
    },

    modalDiscardChanges: {
      attention: 'Tem certeza que deseja cancelar as alterações?',
      warning: 'Esta ação não irá salvar as informações',
      proceed: '',
      confirm: 'Confirmar',
      cancel: 'Cancelar',
    },

    modalSaveHomework: {
      attention: 'Atenção!',
      warning: 'A tarefa será publicada para todos os alunos',
      proceed: 'Tem certeza que deseja publicá-la?',
      confirm: 'Publicar',
      cancel: 'Cancelar',
    },

    modalDeleteChapter: {
      attention: 'Atenção!',
      warning: 'Tem certeza que deseja deletar esse capítulo?',
      proceed: 'Essa ação não poderá ser desfeita',
      confirm: 'Confirmar',
      cancel: 'Cancelar',
    },

    lessonReportModal: {
      attention: 'Tem certeza que deseja marcar a aula como concluída?',
      warning: '',
      proceed: 'Você não poderá desfazer esta ação',
      confirm: 'Confirmar',
      cancel: 'Cancelar',
    },

    modalNoComment: {
      attention: 'Nenhum comentário foi adicionado para o aluno',
      warning: 'Tem certeza que deseja prosseguir?',
      proceed: '',
      confirm: 'Confirmar',
      cancel: 'Cancelar',
    },

    modalChangeKlass: {
      attention: 'As mudanças feitas para essa turmas não foram salvas',
      cancel: 'Cancelar',
      confirm: 'Confirmar',
      warning:
        'Selecionar uma nova turma ou sair da página fará com que você perca suas alterações',
      proceed: 'Tem certeza que deseja prosseguir?',
    },
  },

  modalDisable: {
    disable: 'Desativar',
    cancel: 'Cancelar',
    delete: 'Excluir',
    confirm: 'Confirmar',
    modalDisableActivityElement: {
      warning: 'Tem certeza que deseja desativar esta questão?',
      lostChangesWarning:
        'Esta ação não excluirá as informações da questão para os alunos',
    },

    modalDisableCourse: {
      warning: 'Tem certeza que deseja desativar este curso?',
      lostChangesWarning:
        'Esta ação não excluirá o progresso ou informações dos alunos',
    },

    modalDisableChallenge: {
      warning: 'Tem certeza que deseja desativar este desafio?',
      lostChangesWarning:
        'Esta ação não excluirá o progresso ou informações dos alunos',
    },

    modalDisableUnit: {
      warning: 'Tem certeza que deseja desativar esta unidade?',
      lostChangesWarning: 'Esta ação irá preservar as informações',
    },

    modalDisableKlass: {
      warning: 'Tem certeza que deseja desativar esta turma?',
      lostChangesWarning:
        'Esta ação não fará com que os alunos percam seu progresso',
    },

    modalDisableLesson: {
      warning: 'Tem certeza que deseja excluir esta aula?',
      lostChangesWarning:
        'Esta ação não excluirá o progresso ou informações dos alunos',
    },
    modalDeleteLesson: {
      warning: 'Tem certeza que deseja excluir esta aula?',
      lostChangesWarning:
        'Esta ação excluirá todas as alterações feitas nesta lição',
    },

    modalDisableTeacher: {
      warning: 'Tem certeza que deseja desativar este professor?',
      lostChangesWarning: 'Esta ação irá preservar as informações',
    },

    modalDisableActivity: {
      warning: 'Tem certeza que deseja excluir esta atividade?',
      lostChangesWarning:
        'Esta ação não excluirá o progresso ou informações dos alunos',
    },

    modalRemoveBook: {
      warning: 'Tem certeza que deseja excluir o material?',
      lostChangesWarning:
        'Esta ação não poderá ser desfeita e todas as informações serão perdidas',
    },
    modalDisableTopic: {
      warning: 'Tem certeza que deseja excluir este tópico?',
      lostChangesWarning: 'Esta ação pode afetar o progresso dos alunos',
    },
    modalDisableDifficulty: {
      warning: 'Tem certeza que deseja excluir esta dificuldade?',
      lostChangesWarning: 'Esta ação pode afetar o progresso dos alunos',
    },

    modalStudentAbsence: {
      warning: 'Tem certeza que deseja remover a presença desse aluno?',
      lostChangesWarning:
        'Essa ação também removerá todo o relatório da aula, incluindo notas e comentários, caso já tenham sido preenchidos.',
    },

    modalDisableScheduledLesson: {
      warning: 'Tem certeza que deseja desativar esta aula?',
      lostChangesWarning: 'Essa ação afeterá o calendário de aulas',
    },
    orderLessons: {
      lostChangesWarning:
        'Os alunos já receberão esta atualização e poderão ver que a ordem foi alterada.',
      warning: 'Tem certeza que deseja fazer esta alteração?',
    },
    deleteProject: {
      warning: 'Você tem certeza que deseja deletar o projeto?',
      lostChangesWarning: 'Essa ação não pode ser desfeita',
    },
  },

  avatar: {
    profile: 'Perfil',
  },

  countdown: {
    materialButton: 'Material',
    classroomButton: 'Sala de Aula',
    nextClass: 'PRÓXIMA AULA',
    days: 'dias',
    hours: 'horas',
    minutes: 'minutos',
    seconds: 'segundos',
    accessLesson: 'Acessar Lição',
    watchVideo: 'Ver Vídeo',
    instructionUrlText:
      'Essa aula tem um video de como instalar e/ou usar o programa. Assista antes da sua aula no botão abaixo:',
    materialText:
      'Antes da sua aula começar você já pode acessar o material e ler sobre o assunto no botão abaixo:',
    lessonText:
      'Antes da sua aula começar você já pode ver o conteúdo dela clicando no botão abaixo:',
    accessClassroom: 'Sala de Aula',
  },

  sideMenu: {
    home: 'Início',
    classroom: 'Minhas Aulas',
    feedback: 'Avaliações',
    courses: 'Cursos',
    achievements: 'Conquistas',
    notifications: 'Notificações',
    howWorks: 'Como Funciona',
    admin: 'Admin',
    calendar: 'Calendário',
    config: 'Configurações',
    leave: 'Sair',
    teacher: 'Professor',
    projects: 'Projetos',
  },
  courses: {
    status: {
      EDITING: 'Editando',
      VERSIONING: 'Versionando',
      BETA: 'Beta',
      PUBLISHED: 'Publicado',
      NEW: 'Rascunho',
    },
    legalInformation: 'Informações legais',
  },
  coursePathForm: {
    codeWillOverride:
      'Você está no modo de edição limitada. As alterações feitas irão sobrescrever a versão anterior assim que esta versão for publicada',
    isbnCode: 'Insira o código ISBN ou outras informações legais',
    saveSuccessfuly: 'Alterações salvas com sucesso',
  },
  klassPage: {
    nextKlass: 'Próxima aula',
    myLessons: 'Minhas Aulas',
    myLessonsMessage:
      'Aqui você encontra todas as aulas disponíveis no seu módulo',
    myLessonsCompleted: 'Não há aulas a se fazer!',
    errorMessage: {
      lessonListPhrase: 'Não foi possível carregar a lista de aulas',
      nextLessonPhrase: 'Não foi possível carregar a próxima aula',
    },
  },
  lesson: {
    availableSoon: 'Esta lição estará disponível em breve!',
    availableExplain:
      'Esta lição ainda não está disponível. Você poderá acessá-la uma semana antes da data ou se seu professor alterar a ordem das lições',
    backLessons: 'Ver lições',
    teacher: {
      activity: {
        answerError: 'Ocorreu um erro ao salvar a resposta',
        errorTextSubTitle: 'Houve um erro ao abrir a atividade',
        errorTextTitle: 'Atividade não encontrada',
        klass: {
          errorTextTitle: 'Turma não encontrada',
          errorTextSubTitle: 'Houve um erro ao buscar a turma',
          submittions: 'Envios',
          noStudents: 'Essa turma não tem alunos',
        },
      },
    },
    manage: {
      selectKlass: 'Selecione uma turma',
      bankLesson: {
        title: 'Banco de Aulas',
        details: 'Adicionar uma aula já criada do banco de aulas',
      },
      addLesson: {
        cantAdd:
          'Não é possível adicionar aula entre duas aulas que já passaram',
        title: 'Adicionar Aula',
        details: 'Adicionar uma aula livre ou regular no curso.',
      },
      editLesson: {
        title: 'Editar Aulas',
        details: 'Alterar o conteúdo ou ordem das aulas do curso.',
      },
    },
    access: {
      accessVideo: 'Acessar vídeo',
      accessProgram: 'Acessar programa',
      accessAndInstallation: 'Acesso e Instalação',
      hasVideoInstallationText:
        'Essa aula tem um video de como instalar e/ou usar o programa.',
      clickToWatchVideoInstalationText:
        'Clique nos botões abaixo para assistir o vídeo de como usar o programa e para acessá-lo.',
    },
    attention: 'Atenção!',
    closeActivity: {
      buttonCancel: 'Ficar',
      buttonConfirm: 'Sair',
      warning: 'Se você sair da atividade seu progresso não será salvo',
      confirmMessage: 'Tem certeza de que deseja sair?',
    },
    activityProgress: 'Atividade',
    controlButton: {
      bookNext: 'CONTINUAR',
      bookPrev: 'VOLTAR',
      activityNext: 'PRÓXIMO',
      activityPrev: 'ANTERIOR',
      finish: 'FINALIZAR',
    },
    activity: {
      false: 'F',
      true: 'V',
      titleHeader: 'Atividades',
      feedback: 'Gabarito',
      score: 'Nota',
      notDone: 'Não fez',
      redo: 'Refazer',
      start: 'Iniciar',
      view: 'Visualizar',
      card: 'Atividade',
      homework: 'Tarefa de casa',
      givenByTeacher: 'dado pelo professor',
      noQuestions: 'Nenhuma questão nesta atividade',
      noQuestionsSubtitle:
        'Esta atividade não tem questões, adicione questões ou remova a atividade',
      activityNext: 'PRÓXIMO',
      activityPrev: 'ANTERIOR',
      notFoundTitle: 'Atividade não encontrada',
      notFoundSubtitle: 'Um erro ocorreu ao buscar a atividade',
      result: {
        finished: 'Finalizado',
        resultText: 'Resultado',
        congratulationsText: 'Parabéns, você finalizou a atividade! 🎉',
        questions: 'Questões',
        correct: 'Certas',
        percentage: 'Percentual',
        review: 'Revisar',
        detailReview: 'Revisar as perguntas e suas respostas',
        remake: 'Refazer',
        detailRemake: 'Tentar novamente a atividade',
        leave: 'Sair',
      },
      text: {
        instruction: 'Escreva sua resposta no campo abaixo',
        correction: 'Correção do Professor',
        grade: 'Nota',
      },
      task: {
        instruction: 'Faça upload do arquivo no campo abaixo',
        upload: 'Clique aqui e selecione um arquivo para enviar',
      },
      quiz: {
        statement: 'Escolha a opção correta',

        correctAnswer: 'Resposta correta',
      },
      trueOrFalse: {
        correctAnswer: 'Resposta correta',
        question: 'Verdadeiro ou Falso?',
      },
      dragAndDropOrdenation: {
        instruction: 'Organize os itens na ordem correta',
        errorReordenation: 'Erro ao tentar reordenada os items',
        wrongAnswer: 'Ordem correta',
      },

      dragAndDropPositional: {
        instruction:
          'Organize os itens à direita para corresponder à ordem correta à esquerda',
        errorReordenation: 'Erro ao tentar reordenar os items',
      },
      video: {},
    },
  },

  feedbackPage: {
    pageTitle: 'Avaliação dos Alunos',
    all: 'geral',
    byCourse: 'por curso',
    byModule: 'por módulo',
    byKlass: 'por turma',
    header: {
      average: 'Média',
      lessonRating: 'Conteúdo da aula',
      teacherRating: 'Explicação do professor',
      notFound: 'Nenhuma avaliação encontrada',
      closeFeedback: 'Fechar avaliações',
      loadFeedback: 'Carregar avaliações',
      oldest: 'mais antigas',
      all: 'Todos',
      newest: 'mais recentes',
      positive: 'Positivo 😍',
      neutral: 'Neutro 😐',
      negative: 'Negativo 😖',
    },
    filter: {
      course: 'Curso',
      module: 'Módulo',
      selectCourse: 'Selecione um curso primeiro',
      units: 'Unidades',
      rating: 'Avaliação',
    },
    tag: {
      feedbackSingular: 'avaliação',
      feedbackPlural: 'avaliações',
    },
  },

  accountPage: {
    soundSettings: 'Configurações de som',
    alertSound: 'Som das notificações',
    accountTitle: 'Minhas configurações',
    accountSubheading: 'Aqui você pode alterar as informações da sua conta ',
    accountInfoTitle: 'Dados da Conta',
    accountPasswordTitle: 'Senha da Conta',
    personalInfo: 'Informações Pessoais',
    accountInfo: 'Informações da Conta',
    accountSystemTitle: 'Definições do Sistema',
    theme: 'Tema',
    systemInfo: 'Configurações do Sistema',
    personalInfoTitle: 'Dados Pessoais',
    alterTimezone: 'Fuso-horário',
    fieldName: 'Nome',
    fieldLastname: 'Sobrenome',
    fieldEmail: 'E-mail',
    fieldUsername: 'Username',
    fieldPhoneNumber: 'Telefone',
    fieldDateOfBirth: 'Data de nascimento',
    fieldAddress: 'Cidade, Estado',
    fieldState: 'Estado',
    fieldCity: 'Cidade',
    selectCity: 'Digite a cidade',
    currentPassword: 'Senha Atual',
    saveErrorDifferentPassword: 'As senhas inseridas não coincidem',
    fieldNewPassword: 'Nova senha',
    fieldConfirmPassword: 'Confirme a nova senha',
    saveButton: 'Salvar',
    editButton: 'Editar',
    cancelButton: 'Cancelar',
    saveSuccess: 'Salvo com sucesso',
    saveError:
      'Ocorreu um erro, certifique-se de que a senha atual está correta',
    visualSettings: 'Configurações Visuais',
    changeConsent: 'Alterar informações de consentimento',
  },
  game: {
    edit: {
      title: 'Editar mapa',
      resize: { rows: 'Linhas', cols: 'Colunas' },
      allEnemiesShouldMove: 'Todos os inimigos devem se mover',
    },
    layers: {
      ground: 'Camada de chão',
      interactive: 'Camada interativa',
    },
    selectBlocks: {
      ground: {
        title: 'Blocos',
        description:
          'Clique em um estilo de bloco e depois clique no mapa onde deseja inseri-lo',
      },
      interactive: {
        title: 'Itens',
        description:
          'Itens que interagem com o aluno, clique para adicionar ou remover do mapa',
      },
    },
    validators: {
      [GameErrorCodes.DUPLICATE_SPRITE]:
        'Não é possível adicionar dois items com mesmo sprite',
      [GameErrorCodes.EXISTS_INTERACTIVE_ITEM_IN_COORD]:
        'Já existe um item interativo nessa coordenada',
      [GameErrorCodes.INVALID_COLLECTIBLE_POSITION]:
        'O item coletável não pode estar em um bloco de lava',
      [GameErrorCodes.INVALID_ENEMY_POSITION]:
        'O inimigo não pode estar em um bloco de limite',
      [GameErrorCodes.INVALID_MIN_MATRIX_DIMENSIONS]:
        'A matriz deve ter no mínimo 3x3',
      [GameErrorCodes.INVALID_MAX_MATRIX_DIMENSIONS]:
        'A matriz deve ter no máximo 13x13',
      [GameErrorCodes.INVALID_GROUND_POSITION]:
        'Posição inválida para o bloco de chão, o personagem está nessa posição',
      [GameErrorCodes.GROUND_SHOULD_CONTAIN_ALL_INTERACTIVE_ITEMS]:
        'O chão deve conter todos os itens interativos',
      [GameErrorCodes.PLAYER_SIDE_ENEMY]:
        'Um inimigo que se movimenta não pode estar ao lado do personagem',
      [GameErrorCodes.INVALID_INITIAL_POSITION_PLAYER]:
        'O personagem só pode iniciar em bloco de grama',
      [GameErrorCodes.REGULAR_MATRIX]:
        'A matriz deve ser regular, todas as linhas devem ter o mesmo tamanho',
      [GameErrorCodes.WORKSPACE_UNDEFINED]:
        'O espaço de trabalho não foi definido',
    },
    tokens: {
      names: {
        [GroundToken.FINAL]: 'Pódio',
        [GroundToken.REGULAR]: 'Grama',
        [GroundToken.DANGER]: 'Lava',
        [GroundToken.LIMIT]: 'Limites',
        [InteractiveToken.PLAYER]: 'Personagem',
        [InteractiveToken.COLLECTIBLE]: 'Coletável',
        [InteractiveToken.ENEMY]: 'Inimigo',
        [InteractiveToken.DIRECTION]: 'Indicador de direção do player',
      },
      describes: {
        [GroundToken.DANGER]:
          'Caminho proíbido (o aluno não pode passar por aqui)',
        [GroundToken.REGULAR]: 'Caminho livre (o aluno pode passar por aqui)',
        [GroundToken.FINAL]: 'Ponto de chegada (o aluno deve chegar até aqui)',
        [GroundToken.LIMIT]: 'Bloco de chão que indica o limite do mapa',
        [InteractiveToken.PLAYER]: 'Defina onde o personagem deverá começar',
        [InteractiveToken.COLLECTIBLE]:
          'Adicione ou remova no mapa um item que o aluno deverá coletar',
        [InteractiveToken.ENEMY]:
          'Adicione ou remova um inimigo que o aluno deverá evitar',
        [InteractiveToken.DIRECTION]: 'Indicador de direção do player',
      },
    },
  },
  klass: {
    selectKlass: 'Selecione uma turma',
    lessons: {
      inBank: 'Banco de Aulas',
      readOnly: 'Não editável',
      success: 'Alterações salvas com sucesso',
      cantOrder: 'Não é possível ordenar essa aula',
      errorTextTitle: 'Ocorreu um erro ao carregar as aulas',
      errorTextSubTitle: 'Entre em contato com o suporte',
      finish: 'Finalizar',
      cancel: 'Cancelar',
      view: 'Visualizar',
      title: 'Editar Curso da Turma',
      details: 'Informações',
      activity: 'Atividade',
      studentBook: 'Material do Aluno',
      teacherBook: 'Material do Professor',
      challenge: 'Desafio',
      modal: {
        cancel: {
          success: 'Mudanças descartadas com sucesso',
        },
      },
      lessonDetail: {
        errorTool: 'Link inválido',
        deleteSuccess: 'Aula deletada com sucesso',
        ImageUploadSuccess: 'A imagem foi carregada com sucesso:',
        ImageUploadError_1: 'Atenção: o upload da imagem',
        ImageUploadError_2: ', falhou! Tente novamente!',
        normal: 'Normal',
        free: 'Livre',
        lessonTitle: 'Título da Aula',
        toolUrl: 'Adicione um link de acesso ao programa',
        instructionsUrl: 'Adicione um link de video sobre como usar o programa',
        addLink: 'Adicionar Link',
        save: 'Salvar',
        exclude: 'Excluir Aula',
        freeExplain:
          'Quando o conteúdo da aula é fora do currículo, aumentando a quantidade de aulas total do curso. Recomendado para eventos ou feriados.',
        normalExplain:
          'Quando precisa substituir uma ferramenta ou passar mais tempo para terminar um projeto. Você pode desativar outra aula para manter o total de 18 aulas.',
      },
      alert: {
        saved: 'Salvo',
      },
    },
  },
  modalBank: {
    manage: 'Gerenciar',
    disabledButton: 'Indisponível',
    inUseLesson: 'aula já presente no curso',
    inUseActivity: 'presente no curso',
    private: 'Privado',
    public: 'Público',
    openModal: {
      activities: 'Banco de Atividades',
      questions: 'Banco de Questões',
      lessons: 'Banco de Aulas',
      all: 'Banco de Conteúdo',
    },
    tooltip: {
      outsideBank: 'Fora do banco',
      insideBank: 'Dentro do banco',
    },
    expansibleContent: {
      lesson: {
        description: 'Descrição',
        links: 'Links',
      },
      activity: {
        name: 'Nome',
        viewActivities: 'Visualizar questões',
      },
    },
    selectTypeButton: {
      activities: 'Atividades',
      questions: 'Questões',
      lessons: 'Aulas',
      all: 'Todos',
    },
    list: {
      empty: {
        lessons: 'Nenhuma aula encontrada',
        activities: 'Nenhuma atividade encontrada',
        questions: 'Nenhuma questão encontrada',
        all: 'Nenhum item encontrado',
      },
    },
    section: {
      types: 'Tipos',
      filters: 'Filtros',
    },
    filters: {
      author: 'Autor',
      unit: 'Unidade',
      tool: 'Ferramenta',
      my: 'minha autoria',
      course: 'meu curso',
      search: 'Pesquisar',
    },
    authorFilter: {
      pedagogical: 'Pedagógico',
      my: 'Meu',
      teacher: 'Professor',
      all: 'Todos',
    },
    useButton: {
      activities: 'Utilizar Atividade',
      questions: 'Utilizar Questão',
      lessons: 'Utilizar Aula',
      all: 'Utilizar Conteúdo',
      addingActivity: 'Adicionando atividade...',
    },
  },
  studentHistoric: {
    title: 'Histórico do Aluno',
    enrollTransfer: 'Matrícula transferida da turma',
    enrollCanceled: 'Matrícula cancelada na turma',
    enrolled: 'Matrícula na turma',
    noData: 'Sem informações sobre este aluno',
    fullScreen: 'tela cheia',
  },

  adminPage: {
    noTeachers: 'Nenhum professor encontrado nessa unidade',
    noAdminScreen:
      'A tela do admin não está disponível para dispositivos móveis',
    useComputer: 'Favor utilizar um computador',
    editCourseRules: {
      cantModify: 'Não permitido na edição limitada',
      activityWarning:
        'Você está na edição limitada de cursos e, portanto, alterações como: editar respostas ou criar/excluir questões, não são permitidas',
    },
    createCourse: 'Criar Curso',
    addStudent: 'Adicionar Aluno',
    createTeacher: 'Criar Professor',
    createClass: 'Criar Turma',
    students: 'Alunos',
    teachers: 'Professores',
    courses: 'Cursos',
    topics: 'Tópicos',
    trial: 'Aula Experimental',
    classes: 'Turmas',
    paths: 'Atalhos',
    units: 'Unidades',
    calendars: 'Calendários',
    recesses: 'Recessos',
    team: 'Equipe',
    lesson: {
      modal: {
        beta: {
          title: 'Atenção!',
          warning:
            'O curso será disponibilizado para os professores visualizarem e fazerem sugestões.',
          question: 'Tem certeza de que deseja finalizar a edição?',
          cancel: 'Cancelar',
          confirm: 'Finalizar',
          success: 'O curso foi adicionado para beta com sucesso',
        },
        cancel: {
          title: 'Tem certeza que deseja descartar esta edição?',
          warning:
            'Cancelar todas as alterações é uma ação que não pode ser desfeita. Todas as alterações salvas e não salvas serão perdidas.',
          question: 'Tem certeza de que deseja cancelar todas as alterações?',
          cancel: 'Voltar',
          confirm: 'Descartar',
          success: 'Edição cancelada com sucesso!',
        },
        publishEdit: {
          title: 'Atenção!',
          warning:
            'As alterações serão publicadas para todos os alunos imediatamente. Revise bem as alterações antes de publicá-las',
          question: 'Deseja publicar as alterações?',
          cancel: 'Cancelar',
          confirm: 'Publicar',
          success: 'Curso publicado com sucesso',
        },
        publishBeta: {
          title: 'Atenção!',
          warning:
            'O curso será disponibilizado para novas turmas. Revise e certifique-se que está tudo correto antes de publicar as mudanças.',
          question: 'Tem certeza de que deseja publicar as mudanças?',
          cancel: 'Cancelar',
          confirm: 'Publicar',
          success: 'Alterações publicadas com sucesso',
        },
        enableEdit: {
          title: 'Atenção!',
          warning:
            'Ao habilitar a edição o curso não será mais disponível para professores visualizarem e voltará a ser um rascunho',
          question: 'Tem certeza de que deseja habilitar a edição?',
          cancel: 'Cancelar',
          confirm: 'Habilitar',
        },
      },
      successPublish: 'Curso atualizado com sucesso',
      editingTitle: 'Edição de Curso',
      draftTitle: 'Curso em Rascunho',
      versioningTitle: 'Nova Versão do Curso',
      see: 'Visualizar',
      finish: 'Finalizar',
      publish: 'Publicar',
      discard: 'Descartar',
      editingWarning:
        'Você está na edição limitada de cursos apenas para correções ou alterações pequenas. As alterações publicadas serão exibidas para alunos que já estão matriculados nos cursos. Para fazer mais alterações, selecione a opção "Nova Versão".',
      versioningWarning:
        'A nova versão do curso não será exibida para alunos que já estão matriculados no curso. Somente alunos novos verão as alterações',
      newLesson: 'Nova Aula',
      successDeleteMessage: 'Aula deletada com sucesso',
      successOrderMessage: 'Aulas reordenadas com sucesso',
      errorOrderMessage: 'Ocorreu um erro ao reordenar as aulas',
      errorDeleteMessage: 'Ocorreu um erro ao deletar a aula',
      successCreateMessage: 'Aula criada com sucesso',
      errorCreateMessage: 'Ocorreu um erro ao criar a aula',
      modalDeleteConfirm: 'Tem certeza que deseja excluir esta aula?',
      modalWarning: 'Esta ação não poderá ser desfeita',
      errorNotValidExtension: 'O arquivo deve ser do tipo .zip',

      lessonTabs: {
        details: 'Informações',
        material: 'Material do Aluno',
        teacherMaterial: 'Material do Professor',
        activity: 'Atividade',
        challenge: 'Desafio',
      },
      lessonDetails: {
        title: 'Titulo da Aula:',
        description: 'Descrição da Aula:',
        videoInstallMessage:
          'Se essa aula tem um vídeo sobre como instalar/acessar o programa, insira abaixo:',
        videoId: 'Url do Vídeo:',
        bannerUpload: 'Selecione uma imagem para a lição',
        deleteLesson: 'Excluir Aula',
        saveLesson: 'Salvar',
        programLinkMessage:
          'Caso o programa tenha um endereço de acesso, insira-o abaixo:',
        programUrl: 'Endereço de acesso ao programa:',
        programLinkExample: 'Exemplo: https://www.google.com.br/',
        motivation: 'Mensagem Motivacional:',
        success: 'Aula salva com sucesso',
        error: 'Ocorreu um erro ao tentar salvar a aula',
      },

      addMaterial: {
        materialTitle: 'Título do Material',
        teacherMaterialTitle: 'Título do Material do Professor',
        waitComplete: 'Os capítulos estão sendo formatados. Por favor aguarde.',
        addMaterial: 'Adicionar Material',
        selectMaterial: 'Selecione um arquivo para enviar.',
        errorMessageUploadZip:
          'Atenção: o upload do material falhou! Tente novamente!',
        successDeleteMaterial: 'O Livro foi deletado com sucesso',
        errorDeleteMaterial: 'Ocorreu um erro ao deletar o livro',
        successUploadZip: 'O material foi carregado com sucesso !',
        errorSaveZip: 'Ocorreu um erro ao salvar o material',
        addButton: 'Fazer upload',
        modalConfirm: 'Tem certeza que deseja excluir este livro?',
        modalWarning: 'Esta ação não poderá ser desfeita',
        back: 'Voltar',
      },
      alert: {
        saved: 'Salvo',
      },
    },
    manageUnit: {
      units: 'Unidades',
      title: 'Gerenciamento de Unidades',
      addUnit: 'ADICIONAR UNIDADE',
      totalUnit: 'TOTAL DE UNIDADES',
      successAddUnitMessage: 'Unidade adicionada com sucesso',
      errorAddUnitMessage: 'Ocorreu um erro ao adicionar a unidade',
      successEditUnitMessage: 'Unidade editada com sucesso',
      errorEditUnitMessage: 'Ocorreu um erro ao editar a unidade',
      name: 'Nome',
      timezone: 'Fuso-horário',
      nameErrorMessage: 'Digite o nome da unidade',
      address: 'Endereço',
      neighbourhood: 'Bairro',
      addressErrorMessage: 'Digite um endereço',
      neighbourhoodErrorMessage: 'Digite o bairro',
      addressNumber: 'Número',
      addressNumberErrorMessage: 'Digite o número do endereço da unidade',
      addressComplement: 'Complemento',
      addressReference: 'Referência',
      city: 'Cidade',
      cityErrorMessage: 'Digite a cidade da unidade',
      state: 'Estado',
      stateErrorMessage: 'Selecione o estado da unidade',
      zipcode: 'CEP',
      phone: 'Telefone',
      cnpj: 'CNPJ',
      corporateName: 'Razão social',
      emailErrorMessage: 'Digite o email da unidade',
      add: 'Adicionar',
      save: 'Salvar',
      cancel: 'Cancelar',
      status: 'Status',
      typeStatus: {
        active: 'Ativo',
        inactive: 'Inativo',
      },
    },
    listUnits: {
      errorTextSubTitle: 'Contate o suporte',
      errorTextTitle: 'Erro ao listar as unidades',
      recess: 'Recesso',
      infoTooltip: 'Informações gerais',
      search: 'Pesquisar',
      clearFilters: 'Limpar filtros',
      searchNotFound: 'Busca não encontrada',
      clearOrder: 'Limpar Ordens',
      clearAll: 'Limpar Ordens e Filtros',
      name: 'Nome do Unidade',
      city: 'Cidade',
      state: 'Estado',
      actions: 'Ações',
      editTooltip: 'Editar',
      deleteTooltip: 'Deletar Unidade',
      lastUpdate: 'Última atualização',
      modalConfirmMessage: 'Tem certeza de que quer deletar essa unidade?',
      modalWarningMessage: 'Essa ação não poderá ser desfeita.',
      successDeleteUnitMessage: 'Unidade deletada com sucesso',
      errorDeleteUnitMessage: 'Ocorreu um erro ao deletar a unidade',
      teachers: 'Professores',
      students: 'Estudantes',
      classes: 'Turmas',
      churn: 'Churn',
      presence: 'Presença',
      status: 'Status',
      units: 'Unidades',
    },
  },

  suggestionsPage: {
    suggestions: 'Sugestões',
    seeAll: 'Ver tudo',
    subheading:
      'Aqui você pode sugerir ou votar em novas funcionalidades no portal, e também acompanhar o adamento',
    updates: 'Novidades',
  },

  teacherCalendar: {
    lesson: 'Aula',
    nextLesson: 'Próxima aula:',
    teacherNoKlass: 'Esse professor ainda não possui nenhuma classe',
    endDate: 'Término:',
    students: 'Alunos:',
    noMoreLessons:
      'Esta turma não possui mais nenhuma aula, atualize o status da turma para Inativa',
  },

  teacherPage: {
    title: 'Professor',
    classes: 'Turmas',
    scheduledKlasses: 'Aulas',
    students: 'Alunos',
    reports: 'Relatórios',
  },

  teacherComments: {
    successAdd: 'Comentário adicionado com sucesso',
    errorAdd: 'Houve um erro ao adicionar o comentário',
    errorUpvote: 'Houve um erro ao votar no comentário',
    successDelete: 'Comentário deletado com sucesso',
    errorDelete: 'Erro ao deletar o comentário',
    at: 'às',
    comment: 'Comentário',
    comments: 'Comentários',
    reply: 'Responder',
    submit: 'Enviar',
    response: 'Resposta',
    responses: 'Respostas',
  },

  manageTrialLesson: {
    trialLessons: 'Aulas Experimentais',
    title: 'Gerenciamento de Aula Experimental',
    add: 'Adicionar Horário',
    totalSeats: 'Total de Vagas',
    seatsPerTeacher: 'Vagas por Professor',
    deleteTooltip:
      'Essa aula já tem alunos matriculados, altere a matrícula primeiro',
    noSchedule: 'Esta unidade não possui horários disponíveis',
    filledSeats: 'Vagas Preenchidas',
    deleteSuccess: 'Horário deletado com sucesso',
    deleteError: 'Houve um problema ao deletar o horário',
    attendance: 'Percentual Presença',
    addLesson: {
      canotChangeTeacher: 'Você já adicionou aulas para este professor',
      teacher: 'Professor',
      dates: 'Datas',
      seats: 'Assentos',
      units: 'Unidade',
      notTrialLesson: 'Não ha aulas experimentais agendadas',
      successMessage: 'Aulas experimentais criadas com sucesso',
    },
  },

  manageCourse: {
    courses: 'Cursos - Admin',
    title: 'Gerenciamento de Cursos',
    teachersQuantity: 'TOTAL DE PROFESSORES',
    studentsPerTeacher: 'ALUNOS POR PROFESSOR',
    weeklyAccesses: 'ACESSOS NESSA SEMANA',
    add: 'ADICIONAR CURSO',
    coursesQuantity: 'TOTAL DE CURSOS',
    klassesTotal: 'TOTAL DE TURMAS',
    studentsTotal: 'TOTAL DE ALUNOS',
    addCourse: {
      name: 'Nome do curso',
      slug: 'Nome resumido do curso',
      abbreviation: 'Sigla do curso',
      type: 'Tipo do curso',
      category: 'Categoria',
      f2f: 'Presencial',
      online: 'Online',
      isActive: 'Este curso está ativo?',
      dragger: 'Selecione uma imagem para o curso',
      addButton: 'Adicionar',
      duration: 'Duração do Curso',
      saveButton: 'Salvar',
      addSuccess: 'Curso criado com sucesso!',
      addError: 'Ocorreu um erro ao criar o curso',
      editSuccess: 'Alterações salvas com sucesso',
      editError: 'Ocorreu um erro ao tentar salvar as alterações',
      ImageUploadSuccess: 'A imagem foi carregada com sucesso:',
      ImageUploadError_1: 'Atenção: o upload da imagem',
      ImageUploadError_2: ', falhou! Tente novamente!',
      ImageTypeError: 'A imagem do banner pode ser um destes tipos de arquivo:',
      slugPopover:
        'O nome resumido é necessário para criar a URL(link) do curso! Exemplo Ctrl Kids 2 = ctrl-kids-2',
      errorMessageName: 'Insira o nome do curso!',
      errorMessageSlug: 'Insira o nome resumido do curso!',
      errorMessageAbbreviation: 'Insira a sigla do curso!',
      errorMessageType: 'Selecione o tipo do curso!',
      errorMessageDuration: 'Insira a duração do curso!',
      cancelButton: 'Cancelar',
      Quick: 'RÁPIDO',
      typeSelect: 'Selecione o tipo do curso',
    },
    editCourse: {
      category: 'Categoria',
      abbreviation: 'Sigla do curso',
      duration: 'Duração',
      isActive: 'Status',
      name: 'Nome do curso',
      type: 'Tipo do curso',
      errorMessageAbbreviation: 'Insira a sigla do curso!',
      errorMessageName: 'Insira o nome do Curso',
      errorMessageDuration: 'Insira a duração do curso!',
      saveButton: 'Salvar',
      cancelButton: 'Cancelar',
      dragger: 'Selecione uma imagem para o curso',
      published: 'Publicado',
      disabled: 'Desabilitado',
      editing: 'Editando',
      versioning: 'Versionando',
      editError: 'Ocorreu um erro ao editar o curso',
      editSuccess: 'Alterações salvas com sucesso',
      ImageUploadError_1: 'Atenção: o upload da imagem',
      ImageUploadError_2: ', falhou! Tente novamente!',
      ImageUploadSuccess: 'A imagem foi carregada com sucesso:',
      active: 'Ativo',
      inactive: 'Inativo',
      Quick: 'RÁPIDO',
      editButton: 'Editar',
      info: 'Informações Gerais',
      saveCreated: 'Curso criado com sucesso',
      saveEdited: 'Curso editado com sucesso',
      wanings: {
        emptyField: 'Preencha esse campo',
        noChanges: 'Nenhuma alteração identificada',
      },
      errors: {
        name: {
          maxLength: 'O nome não deve ultrapassar 200 caracteres',
        },
        abbreviation: {
          maxLength: 'A sigla não deve ultrapassar 7 caracteres',
          exists: 'Essa sigla já existe',
        },
      },
    },
    dataCourseTable: {
      editTooltip: 'Editar Curso',
      deleteTooltip: 'Deletar Curso',
      modalConfirm: 'Tem certeza que deseja excluir este curso?',
      modalWarning:
        'Todo o progresso do curso será perdido e esta ação não pode ser desfeita',
      deleteSuccess: 'Curso deletado com sucesso',
      deleteFail: 'Ocorreu um erro ao tentar deletar o curso',
      branchTooltip: 'Nova Versão',
      infoTooltip: 'Info',
      viewTooltip: 'Ver Curso',
      Quick: 'RÁPIDO',
      statisticNotFound: 'N/A',
      searchNotFound: 'Busca não encontrada',
      noCourses: 'Não há cursos cadastrados',
      noCoursePath:
        'Não é possível versionar esse curso. Por favor contate o suporte',
      school: 'Escola',
    },
    listCourses: {
      search: 'Pesquisar',
      clearFilters: 'Limpar filtros',
      clearOrder: 'Limpar Ordens',
      clearAll: 'Limpar Ordens e Filtros',
      name: 'Curso',
      type: 'Tipo',
      students: 'Alunos',
      status: 'Status',
      actions: 'Ações',
      category: 'Categoria',
      churn: 'Churn',
      presence: 'Presença',
      version: 'Versão',
    },
  },
  manageStudent: {
    students: 'Alunos',
    title: 'Gerenciamento de Alunos',
    accesses: 'TOTAL DE ACESSOS',
    studentWarning:
      'Atenção! Não é possível fazer edições em alunos pelo Portal, somente pela Loja',
    weeklyAccesses: 'ACESSOS NESSA SEMANA',
    quantity: 'TOTAL DE ALUNOS',
    add: 'ADICIONAR ALUNO',
    back: 'voltar',
    status: userStatus,
    listUsers: {
      search: 'Pesquisar',
      clearFilters: 'Limpar filtros',
      clearOrder: 'Limpar Ordens',
      clearAll: 'Limpar Ordens e Filtros',
      name: 'Nome',
      email: 'E-mail',
      unit: 'Unidade',
      klass: 'Turma',
      phoneNumber: 'Telefone',
      role: 'Cargo',
      lastAccess: 'Último Acesso',
      status: 'Status',
      actions: 'Ações',
    },
    dataUserTable: {
      passwordTooltip: 'Alterar Senha',
      infoTooltip: 'Informações Gerais',
      never: 'Nunca',
      searchNotFound: 'Busca não encontrada',
      noStudents: 'Não há alunos cadastrados',
      rewardsTooltip: 'Recompensas',
      pedagogicalSheetTooltip: 'Ficha Pedagógica',
      historicTooltip: 'Histórico do Aluno',
    },
    addUser: {
      info: 'Informações Gerais',
      edit: 'Editar',
      unit: 'Unidade',
      name: 'Nome',
      lastName: 'Sobrenome',
      email: 'E-mail',
      username: 'Usuário',
      selectCity: 'Digite a cidade',
      usernameRule: 'Somente letras, ponto, hifen e Underline',
      password: 'Senha',
      passwordConfirm: 'Confirme a senha',
      errorMessagePasswordMatch: 'As senhas inseridas não coincidem',
      birth: 'Aniversário',
      state: 'Estado',
      city: 'Cidade',
      bio: 'Bio',
      klass: 'Turma',
      phoneNumber: 'Telefone',
      role: 'Cargo',
      status: 'Status',
      Registred: 'Registrado',
      Active_Enrollment: 'Matricula Ativa',
      Inactive: 'Inativo',
      Waiting_Enrollment: 'Aguardando Matricula',
      errorMessageUnit: 'Selecione a Unidade',
      errorMessageName: 'Insira o nome do estudante',
      errorMessageLastName: 'Insira o sobrenome do estudante',
      errorMessageEmail: 'Insira o e-mail do estudante',
      errorMessageUsername: 'Insira o usuário do estudante',
      errorMessagePassword: 'Insira a senha do estudante',
      errorMessagePasswordConfirm: 'Confirme a senha do estudante',
      errorMessageBirth: 'Insira a data de nascimento do estudante',
      errorMessageState: 'Insira o estado do estudante',
      errorMessageCity: 'Insira a cidade do estudante',
      errorMessageKlass: 'Insira uma turma para o estudante',
      addButton: 'Adicionar',
      successCreateUser: 'Estudante criado com sucesso',
      errorCreateUser: 'Ocorreu um erro ao criar o estudante',
      successEditUser: 'Alterações salvas com sucesso',
      errorEditUser: 'Ocorreu um erro ao salvar as alterações',
      saveButton: 'Salvar',
      cancelButton: 'Cancelar',
      addUserRuleOne:
        'apenas letras, números e . - _ são aceitos para username ou e-mail',
      addUserRuleTwo: 'preencha todos os campos',
      addUserRuleThree: 'certifique-se que os campos de senha coincidem',
    },
  },

  manageTeam: {
    team: 'Time',
    teacherWarning:
      'Atenção! Não é possível criar professores ou fazer algumas edições pelo Portal, somente pela Loja',
    title: 'Gerenciamento de Equipe',
    accesses: 'TOTAL DE ACESSOS',
    weeklyAccesses: 'ACESSOS NESSA SEMANA',
    quantity: 'TOTAL DE MEMBROS',
    add: 'ADICIONAR EQUIPE',
    back: 'voltar',
    SUPER_ADMIN: 'Super Administrador',
    UNIT_ADMIN: 'Administrador',
    TEACHER: 'Professor',
    fromUnit: 'De outra unidade',
    newUser: 'Novo usuário',
    errorUserAlreadyHasUnit: 'Usuário já está nessa unidade',
    selectUser: 'Selecione o usuário',
    selectUnit: 'Seleciona a unidade',
    unitLabel:
      'O usuário manterá a(s) unidade(s) antiga, além da nova selecionada abaixo:',
    status: userStatus,
    listUsers: {
      search: 'Pesquisar',
      clearFilters: 'Limpar filtros',
      clearOrder: 'Limpar Ordens',
      clearAll: 'Limpar Ordens e Filtros',
      name: 'Nome',
      email: 'E-mail',
      unit: 'Unidade',
      klass: 'Turma',
      phoneNumber: 'Telefone',
      role: 'Cargo',
      lastAccess: 'Último Acesso',
      status: 'Status',
      actions: 'Ações',
    },
    dataUserTable: {
      passwordTooltip: 'alterar senha',
      infoTooltip: 'informações gerais',
      never: 'Nunca',
      searchNotFound: 'Busca não encontrada',
    },
    addUser: {
      info: 'Informações Gerais',
      edit: 'Editar',
      selectCity: 'Digite a cidade',
      selectRole: 'Selecione o cargo',
      unit: 'Unidade',
      name: 'Nome',
      lastName: 'Sobrenome',
      email: 'E-mail',
      username: 'Usuário',
      usernameRule: 'Somente letras, ponto, underline e números',
      password: 'Senha',
      passwordConfirm: 'Confirme a senha',
      errorMessagePasswordMatch: 'As senhas inseridas não coincidem',
      birth: 'Nascimento',
      state: 'Estado',
      city: 'Cidade',
      bio: 'Bio',
      klass: 'Turma',
      phoneNumber: 'Telefone',
      role: 'Cargo',
      status: 'Status',
      Registred: 'Registrado',
      Active_Enrollment: 'Matricula Ativa',
      Inactive: 'Inativo',
      Waiting_Enrollment: 'Aguardando Matricula',
      errorMessageUnit: 'Selecione a Unidade',
      errorMessageName: 'Insira o nome do membro da equipe',
      errorMessageLastName: 'Insira o sobrenome do membro da equipe',
      errorMessageEmail: 'Insira o e-mail do membro da equipe',
      errorMessageUserType: 'Selecione o tipo de usuário',
      errorMessageUsername: 'Insira o usuário do membro da equipe',
      errorMessagePassword: 'Insira uma senha',
      errorMessagePasswordConfirm: 'Confirme a senha',
      errorMessageBirth: 'Insira a data de nascimento do membro da equipe',
      errorMessageState: 'Insira o estado do membro da equipe',
      errorMessageCity: 'Insira a cidade do membro da equipe',
      errorMessageKlass: 'Insira uma turma para o membro da equipe',
      addButton: 'Adicionar',
      successCreateUser: 'Funcionário criado com sucesso',
      errorCreateUser: 'Ocorreu um erro ao criar o membro da equipe',
      successEditUser: 'Alterações salvas com sucesso',
      errorEditUser: 'Ocorreu um erro ao salvar as alterações',
      saveButton: 'Salvar',
      cancelButton: 'Cancelar',
      addUserRuleOne:
        'Apenas letras, números e . - _ são aceitos para username',
      addUserRuleTwo: 'preencha todos os campos',
      addUserRuleThree: 'certifique-se que os campos de senha coincidem',
    },
  },

  manageTeacher: {
    teachers: 'Professores',
    title: 'Gerenciamento de Professores',
    quantity: 'TOTAL DE PROFESSORES',
    accesses: 'TOTAL DE ACESSOS',
    weeklyAccesses: 'ACESSOS NESSA SEMANA',
    add: 'ADICIONAR PROFESSOR',
    back: 'voltar',
    status: userStatus,
    listUsers: {
      search: 'Pesquisar',
      clearFilters: 'Limpar filtros',
      clearOrder: 'Limpar Ordens',
      clearAll: 'Limpar Ordens e Filtros',
      name: 'Nome',
      email: 'E-mail',
      unit: 'Unidade',
      klass: 'Turma',
      phoneNumber: 'Telefone',
      role: 'Cargo',
      lastAccess: 'Último Acesso',
      status: 'Status',
      actions: 'Ações',
      students: 'Alunos',
      presence: 'Presença',
      churn: 'Churn',
    },
    dataUserTable: {
      noTeachers: 'Não há professores cadastrados',
      searchNotFound: 'Busca não encontrada',
      passwordTooltip: 'Alterar Senha',
      editTooltip: 'Editar Professor',
      deleteTooltip: 'Deletar Professor',
      never: 'Nunca',
      IntoTooltip: 'Informações gerais',
      lessonsTooltip: 'Aulas',
      noProfileFound: 'Perfil do professor não encontrado',
    },
    addUser: {
      info: 'Informações Gerais',
      edit: 'Editar',
      unit: 'Unidade',
      name: 'Nome',
      lessons: 'Aulas',
      filters: {
        allLessons: 'todas as aulas',
        completedLessons: 'aulas concluídas',
        plannedLessons: 'aulas programadas',
      },
      noContent: {
        allLessons: 'Nenhuma aula para esse professor',
        completedLessons: 'Nenhuma aula concluída por esse professor',
        plannedLessons: 'Nenhuma aula programada para esse professor',
      },
      lastName: 'Sobrenome',
      email: 'E-mail',
      username: 'Usuário',
      usernameRule: 'Somente letras, ponto, hifen e Underline',
      password: 'Senha',
      passwordConfirm: 'Confirme a senha',
      birth: 'Nascimento',
      state: 'Estado',
      city: 'Cidade',
      selectCity: 'Digite a cidade',
      bio: 'Bio',
      klass: 'Turma',
      phoneNumber: 'Telefone',
      role: 'Cargo',
      status: 'Status',
      Registred: 'Ativo',
      Active_Enrollment: 'Matricula Ativa',
      Inactive: 'Inativo',
      Waiting_Enrollment: 'Aguardando Matricula',
      errorMessageUnit: 'Selecione a Unidade',
      errorMessageName: 'Insira o nome do professor',
      errorMessageLastName: 'Insira o sobrenome do professor',
      errorMessageEmail: 'Insira o e-mail do professor',
      errorMessageUsername: 'Insira o usuário do professor',
      errorMessagePassword: 'Insira a senha do professor',
      errorMessagePasswordConfirm: 'Confirme a senha do professor',
      errorMessagePasswordMatch: 'As senhas inseridas não coincidem',
      errorMessageBirth: 'Insira a data de nascimento do professor',
      errorMessageState: 'Insira o estado do professor',
      errorMessageCity: 'Insira a cidade do professor',
      errorMessageKlass: 'Insira uma turma para o professor',
      addButton: 'Adicionar',
      successCreateUser: 'Professor criado com sucesso',
      errorCreateUser: 'Ocorreu um erro ao criar o professor',
      successEditUser: 'Alterações salvas com sucesso',
      errorEditUser: 'Ocorreu um erro ao salvar as alterações',
      saveButton: 'Salvar',
      cancelButton: 'Cancelar',
      addUserRuleOne:
        'apenas letras, números e . - _ são aceitos para username ou e-mail',
      addUserRuleTwo: 'preencha todos os campos',
      addUserRuleThree: 'certifique-se que os campos de senha coincidem',
      typeStatus: {
        active: 'Ativo',
        inactive: 'Inativo',
      },
    },
  },

  manageKlasses: {
    status: klassStatus,
    klasses: 'Turmas',
    title: 'Gerenciamento de Turmas',
    klassesPerTeacher: 'ALUNOS POR TURMA',
    klassesQuantity: 'TOTAL DE TURMAS',
    studentsPerTeacher: 'ALUNOS POR PROFESSOR',
    weeklyAccesses: 'ACESSOS NESSA SEMANA',
    add: 'ADICIONAR TURMA',
    addKlass: {
      selectDayTimes: 'Selecione primeiro o dia da semana e horário da turma',
      info: 'Informações Gerais',
      warning: 'Alterações na turma só podem ser feitas pela Loja',
      edit: 'Editar',
      course: 'Curso',
      f2f: 'Presencial',
      online: 'Online',
      startDate: 'Início',
      endDate: 'Fim',
      klassStatus,
      duration: 'Duração',
      status: 'Status',
      klassDateAndTime: 'Dia e Horários',
      recurrence: 'Recorrência',
      recessCalendar: 'Calendario',
      teacher: 'Professor',
      students: 'Alunos',
      add: 'Adicionar',
      save: 'Salvar',
      cancel: 'Cancelar',
      addStudentButton: 'Adicionar Aluno',
      addSuccess: 'Turma criada com sucesso',
      addError: 'Ocorreu um erro ao criar a turma',
      saveSuccess: 'Alterações salvas com sucesso',
      saveError: 'Ocorreu um erro ao salvar as alterações',
      moveTooltip: 'Mover Aluno',
      deleteTooltip: 'Remover Aluno',
      unit: 'Unidade',
      meetLink: 'Link da Reunião',
      dayOfWeek: 'Dia da semana',
      time: 'Horário',
      day: 'Dia',
      error: {
        unit: 'Selecione a unidade',
        course: 'Inserir o curso',
        klassDate: 'Inserir a data de início do curso',
        klassTime: 'Inserir o horario da turma',
        recurrence: 'Insira a recorrência da turma',
        recessCalendar: 'Insira o Calendario de Recessos',
        dayWeek: 'Insira o dia da semana',
        teacher: 'Insira o professor da turma',
        students: 'Insira pelo menos um aluno',
        duration: 'Insira a duração de cada aula',
        invalidTime: 'Insira um horário válido',
        klassType: 'Insira o tipo de turma',
        klassStatus: 'Insira o status da turma',
      },
      select: {
        course: 'Selecione o curso',
        day: 'Selecione um dia da semana',
        duration: 'Selecione a duração da aula',
        teacher: 'Selecione o professor',
      },

      maxCapacity: 'Capacidade máxima',
      conectaSeats: 'Assentos do conecta',
      klassType: 'Tipo da turma',
      coursePathId: 'Curso',
    },
    dataKlassTable: {
      searchNotFound: 'Busca não encontrada',
      noKlasses: 'Não há turmas cadastradas',
      infoTooltip: 'Informações Gerais',
      editTooltip: 'Editar Classe',
      noDate: 'Sem data',
      processingEnrollment: 'Matrícula em Processamento',
      teacherStudentsTooltip: 'Professor e alunos',
      classProgressTooltip: 'Progresso da turma',
      klassSheetTooltip: 'Ficha da turma',
      teacherStudentsPill: 'Professores e alunos',
      lessonsTooltip: 'Aulas marcadas',
      deleteTooltip: 'Deletar Classe',
      modalConfirm: 'Tem certeza que deseja excluir essa classe?',
      modalWarning:
        'Todo o progresso do classe será perdido e esta ação não pode ser desfeita',
      deleteSuccess: 'Classe deletada com sucesso',
      deleteFail: 'Ocorreu um erro ao tentar deletar o classe',
      teacher: 'Professor',
      students: 'Alunos',
      noStudentsToFollow: 'Sem alunos para acompanhar',
      months: {
        january: 'Janeiro',
        february: 'Fevereiro',
        march: 'Março',
        april: 'Abril',
        may: 'Maio',
        june: 'Junho',
        july: 'Julho',
        august: 'Agosto',
        september: 'Setembro',
        october: 'Outubro',
        november: 'Novembro',
        december: 'Dezembro',
      },
      scheduledLessonProgressView: {
        klasses: 'Aulas',
        everyKlass: 'todas as aulas',
        completedKlasses: 'aulas concluídas',
        scheduledKlasses: 'aulas programadas',
        noScheduledLessons: 'Nenhuma lição para exibir',
        pendingKlasses: 'com relatórios pendentes',
        ascending: 'crescente',
        descending: 'decrescente',
        sort: 'Ordem',
      },
    },
    listKlasses: {
      search: 'Pesquisar',
      clearFilters: 'Limpar filtros',
      clearOrder: 'Limpar Ordens',
      clearAll: 'Limpar Ordens e Filtros',
      klass: 'Classe',
      unit: 'Unidade',
      start: 'Início',
      end: 'Término',
      status: 'Status',
      actions: 'Ações',
      activeClasses: 'Classes ativas:',
    },
  },

  klassProgressPage: {
    lessonBlocked:
      'Esta lição ainda não está liberada, ela ficará disponível em breve',
    pageTitle: 'Acompanhamento da Turma',
    notFoundTitle: 'Parece que a turma que você está buscando não existe',
    notFoundText: 'Favor tente outra turma',
    genericErrorTitle: 'Ops, tivemos um problema',
    genericErrorText: 'Contate o time de suporte ou tente novamente mais tarde',
    noStudents: 'Essa turma ainda não tem nenhum aluno, selecione outra turma',
    lesson: 'Aula',
    lessons: 'Aulas',
    students: 'Alunos',
    attendances: 'Pres.',
    noProgress: 'Sem progresso nesta aula associado à esta turma',
    absences: 'Faltas',
    aboutKlass: 'Sobre a turma',
    progressAverage: 'Média do Progresso',
    averagePP: 'Média PP',
    pleaseFillReport: 'Por favor, preencha o relatório',
    givenContent: 'Conteúdo Dado',
    lessonScheduled: 'Aula Prevista',
    lessonIsScheduled: 'Esta aula está prevista para os próximos dias',
    reportNotFilled: 'Relatório não preenchido',
    seeReport: 'ver relatório',
    missed: 'Falta',
    noActiveStudent: 'Nehum aluno ativo',
    noInactiveStudent: 'Nenhum aluno inativo',
    attended: 'Presente',
    active: 'Ativos',
    inactive: 'Inativos',
    all: 'Todos',
    transferred: 'Transferido',
    pending: 'Pendente',
    done: 'Feito',
  },

  activity: {
    errorTextTitle: 'Erro ao carregar as atividade',
    errorTextSubTitle:
      'Verifique se está na página correta ou contate o suporte',
    newActivity: 'Nova Atividade',
    textLabel: 'Texto',
    videoLabel: 'Video',
    quizLabel: 'Quiz',
    codeLabel: 'Código',
    trueFalseLabel: 'V/F',
    dragAndDropOrdenation: 'Drag e Drop de ordenação',
    dragAndDropPositional: 'Drag and Drop positional',
    deleteSuccess: 'Atividade deletada com sucesso',
    deleteFail: 'Ocorreu um erro ao deletar a atividade',
    addSuccess: 'Atividade Adicionada',
    addError: 'Erro ao adicionar aula',
    modalConfirm: 'Tem certeza que deseja excluir esta Atividade?',
    modalWarning: 'Esta ação não poderá ser desfeita',
    manageActivity: {
      editActivity: 'Editar atividade',
      errorTextTitle: 'Erro ao carregar as atividade',
      errorTextSubTitle:
        'Verifique se está na página correta ou contate o suporte',
      newQuestion: 'Nova Questão',
      notAllowed: 'Não permitido na edição limitada',
      save: 'Salvar',
      add: 'Adicionar',
      detailsLabel: 'Informações',
      activityTypes: {
        TEXT: 'Texto',
        VIDEO: 'Video',
        QUIZ: 'Quiz',
        TRUE_OR_FALSE: 'V/F',
        DND_ORDENATION: 'Arrastar',
        DND_POSITIONAL: 'Posicionar',
        CODE: 'Código',
      },
      examLabel: 'Teste',
      pdfLabel: 'PDF',
      taskLabel: 'Tarefa',
      validationError: 'Erro ao salvar, corrija os erros',
      saveSuccess: 'Atividade salva com sucesso',
      deleteSuccess: 'Atividade deletada com sucesso',
      saveError: 'Ocorreu um erro ao tentar salvar a atividade',
      activityDetails: {
        name: 'Título da Atividade',
        inBank: 'Adicionar ao Banco de Atividades',
        isPublicInBank: 'Público',
        config: '',
        noQuestionsWarning:
          'Nenhuma questão criada, clique em + Nova Questão para adicionar uma questão',
        save: 'Salvar',
        delete: 'Excluir Atividade',
        deleteError: 'Ocorreu um erro ao tentar deletar a atividade',
        deleteSuccess: 'Atividade deletada com sucesso',
        saveError: 'Ocorreu um erro ao tentar salvar a atividade',
        saveSuccess: 'Atividade salva com sucesso',
      },
      activityCode: {
        title: { label: 'Titulo', required: 'O titulo é obrigatório' },
        tip: {
          label: 'Dica',
          placeholder: 'Opcional: Adicione uma dica caso o aluno se perca',
        },
        instructions: {
          label: 'Instruções',
          placeholder: 'Insira as instruções da atividade',
          required: 'As instruções são obrigatórias',
        },
        map: {
          run: 'RODAR',
          reset: 'RESETAR',
          edit: 'Editar Mapa',
        },
        workspaceOptions: {
          title: 'Opções',
          maxBlocks: 'Limite de Blocos',
          maxBlocksAlert:
            'O limite de blocos não pode ser menor que o número de blocos já adicionados',
          coins: 'Moedas',
        },
        save: 'Salvar',
        success: 'Atividade salva com sucesso',
      },
      activityText: {
        title: 'Enunciado:',
        titleError: 'Insira o titulo do texto',
        subtitle: 'Subtítulo',
        subtitleError: 'Insira o subtítulo do texto',
        content: 'Conteúdo:',
        contentError: 'Escreva alguma coisa como conteudo da questão',
        save: 'Salvar',
        saveSuccess: 'Atividade salva com sucesso',
        saveError: 'Ocorreu um erro ao salvar a atividade',
      },
      activityVideo: {
        title: 'Enunciado:',
        titleError: 'Insira o titulo do vídeo',
        subtitle: 'Subtítulo:',
        subtitleError: 'Insira o subtítulo do vídeo',
        videoID: 'Id do Vídeo:',
        videoIDError: 'O vídeo deve ter uma ID',
        videoPlayer: 'Vídeo Player:',
        videoPlayerError: 'Selecione um dos players acima',
        exampleMessage1: 'Exemplo: insira ',
        exampleMessage2: ' da URL ',
        save: 'Salvar',
        saveSuccess: 'Atividade salva com sucesso',
        saveError: 'Ocorreu um erro ao salvar a atividade',
      },
      activityQuiz: {
        answers: {
          answers: 'Respostas:',
          noValue: 'Resposta sem conteúdo',
          cantRemove: 'O quiz deve ter no mínimo 2 alternativas',
        },
        image: 'imagem',
        text: 'texto',
        cantChangeType:
          'Não é possível alterar tipo desse quiz. Crie um novo quiz.',
        imageDescription: 'Descrição da imagem:',
        missingAlt: 'Insira a descrição das imagens',
        missingImages: 'Algumas alternativas estão sem imagem',
        title: 'Enunciado:',
        content: 'Conteúdo:',
        titleError: 'A questão precisa de um enunciado',
        answersError: 'Pelo menos duas respostas devem ser adicionadas',
        atLeastOneTrueAnswerError: 'Uma resposta deve ser marcada como correta',
        newAnswer: 'Nova Resposta',
        justificative: 'Explicação da resposta certa:',
        justificativeError: 'A questão precisa de uma justificativa',
        save: 'Salvar',
        saveSuccess: 'Atividade salva com sucesso',
        saveError: 'Ocorreu um erro ao salvar a atividade',
      },
      activityTrueFalse: {
        title: 'Enunciado:',
        content: 'Conteúdo:',
        titleError: 'A questão precisa de um enunciado',
        trueOrFalse: 'O enunciado é verdadeiro ou falso?',
        trueOrFalseError: 'Uma opção deve ser selecionada como resposta',
        true: 'Verdadeiro',
        false: 'Falso',
        justificative: 'Explicação da resposta certa:',
        justificativeError: 'A questão precisa de uma justificativa',
        save: 'Salvar',
        saveSuccess: 'Atividade salva com sucesso',
        saveError: 'Ocorreu um erro ao salvar a atividade',
      },
      challenge: {
        title: 'Desafio',
        save: 'Salvar',
        add: 'Adicionar Desafio',
        deleteSuccess: 'Desafio deletado com sucesso',
        deleteFail:
          'Houve um erro ao deletar o desafio, tente novamente mais tarde ou contate o time de suporte',
        edit: 'Editar Desafio',
        delete: 'Excluir Desafio',
        cancel: 'Cancelar',
        saveSuccess: 'Desafio salvo com sucesso',
        saveError: 'Ocorreu um erro ao salvar o desafio',
        content: 'Conteúdo',
      },
      activityDNDOrdenation: {
        title: 'Enunciado:',
        subtitle: 'Subtítulo:',
        columns: 'Colunas:',
        columnName: 'Nome da coluna:',
        text: 'Texto',
        descriptionImage: 'Descrição da imagem',
        newItem: 'Novo item',
        newColumn: 'Adicionar nova coluna',
        removeColumn: 'Remover coluna',
        save: 'Salvar',
        textTooltipRemoveColumn: 'Só pode remover se tiver mais de uma coluna',
        textTooltipRemoveItem: 'Só pode remover se tiver mais de três itens',
        nameColumnRequired: 'Nome da coluna é obrigatório',
        noAltText: 'Adicionar uma descrição para a imagem',
        noImage: 'Adicionar uma imagem',
        noContent: 'Adicione algum conteúdo no item',
        noTitle: 'A questão precisa de um enunciado',
        saveSuccess: 'Atividade salva com sucesso',
        saveOrderSuccess: 'Nova ordem salva',
        saveDeleteItemSuccess: 'Item deletado',
        saveAddItemSuccess: 'Novo item adicionado',
        saveDeleteColumnSuccess: 'Coluna deletada',
        saveAddColumnSuccess: 'Coluna adicionada',
        maxLength: 'O conteúdo não deve ultrapassar 150 caracteres',
        maxLengthAltText:
          'A descrição da imagem não deve ultrapassar 150 caracteres',
      },

      dndPositionalElementEdit: {
        saveSuccess: 'Atividade salva com sucesso',
        saveOrderSuccess: 'Nova ordem salva',
        saveDeleteSuccess: 'Item deletado',
        saveAddItemSuccess: 'Novo item adicionado',
        dndErrors: {
          onlyOneItem: 'A atividade deve conter mais itens',
        },
        dndActivityInfo: {
          title: 'Enunciado:',
          subtitle: 'Subtítulo:',
          dndErrors: {
            noTitle: 'A questão precisa de um enunciado',
          },
        },
        dndColumnEdit: {
          columnName: 'Nome da coluna:',
          descriptionImage: 'Descrição da imagem',
          newItem: 'Novo item',
          question: 'Pergunta:',
          answer: 'Resposta:',
          textTooltipRemoveItem: 'Só pode remover se tiver mais de um item',
          textTooltipReorderItem: 'Só pode reordenar se tiver mais de um item',
          dndErrors: {
            nameColumnRequired: 'Nome da coluna é obrigatório',
            noTitle: 'A questão precisa de um enunciado',
            noContent: 'Adicione algum conteúdo no item',
            noAltText: 'Adicione uma descrição para a imagem',
            maxLength: 'O conteúdo não deve ultrapassar 150 caracteres',
            maxLengthAltText:
              'A descrição da imagem não deve ultrapassar 150 caracteres',
          },
        },
        save: 'Salvar',
      },
    },

    activityBank: 'Banco de atividades',
  },
  changeLanguage: 'Mudar Idioma',
  configuration: 'Configuração',
  back: 'voltar',

  dashboard: {
    hello: 'Olá, ',
    wellcome: {
      teacher:
        'Seja bem vindo de volta professor(a)! Pronto para continuar sua jornada? 🚀',
      admin: 'Bem vindo de volta administrador!',
      unitAdmin: 'Bem vindo de volta administrador da unidade!',
      parent:
        'Bem vindo à Ctrl+Play, pronto para transformar o futuro do seu filho?',
    },
    yourCourse: 'CURSO',
    accessClasses: 'Aulas',
    totalClasses: 'AULAS',
    totalActivities: 'ATIVIDADES',
    welcome:
      'Seja bem vindo à Ctrl + Play. Dê uma olhada sobre como nossos cursos funcionam e faça sua matrícula!',
    dashboardClass: {
      teacher: 'Professor',
      yourClass: 'SUA TURMA',
      colleagues: 'Colegas',
      noTeacherText: 'Professor não selecionado',
      teacherKlassMembers:
        'Selecione uma turma para ver os avatares e acessar o perfil dos alunos',
    },
    error: 'Não foi possível carregar o progresso',
    support:
      'Parece que algo deu errado. Por favor, fale com a equipe de suporte',
  },
  coursesView: {
    myLessons: 'Minhas Aulas',
    myCourses: 'Meus Cursos',
    myCoursesInstructions:
      'Aqui você pode acessar todas as aulas e atividades disponíveis no seu curso',
    completedCourses: 'CURSOS CONCLUÍDOS',
    nextSteps: 'Próximos Passos',
    continueText:
      'Continue a evoluir e aprender com os cursos mais avançados da Ctrl+Play.',
    admin: {
      errorTextTitle: 'Course não encontrado',
      errorTextSubTitle: 'Verifique se você está na página correta',
      unpublishedCourses: 'Cursos não publicados',
      betaDraftExplanation:
        'Os cursos em ”Beta” abaixo estão disponíveis para alguns professores olharem, revisarem ou fazerem sugestões. Cursos em ”Rascunho” ainda estão sendo editados e não são exibidos para os professores até que as edições sejam finalizadas.',
      courses: 'Cursos',
      finish: 'Finalizar',
      publish: 'Publicar Curso',
      backEditing: 'Continuar Editando',
      f2f: 'Presencial',
      online: 'Online',
      newCourse: 'Novo Curso',
      viewCourse: 'Visualizar Curso',
      newVersion: 'Nova Versão',
      editCourse: 'Editar Curso',
      enableEditing: 'Habilitar Edição',
      myLessons: 'Minhas Aulas',
      addExtraLesson: {
        add: 'Adicionar',
        newLesson: 'Título da Aula',
        cancel: 'Cancelar',
        selectLessonExplain:
          'A aula extra será adicionada antes da lição selecionada. Você não pode selecionar aulas que já passaram.',
        selectLesson: 'Selecione uma lição',
        title: 'Selecione uma lição',
      },
    },
    progress: 'PROGRESSO',
  },
  profile: {
    title: 'Meu Perfil',
    level: {
      levelName: 'Nível {{level}}',
      levelProgress: '{{currentXp}}/{{targetXp}} EXP',
    },
    errors: {
      username: 'Este nome de usuário já existe',
      links: 'Link inválido',
    },
    sixLinks: 'É permitido adicionar até 6 links',
    addLinks: 'Adicionar Link',
    saveProfile: 'Salvar Perfil',
    editProfile: 'Editar Perfil',
    saveSuccess: 'Alterações salvas com sucesso',
    saveError:
      'Ocorreu um erro, tente novamente mais tarde ou contate o time de suporte',
    notFoundTitle: 'Ops, nenhum usuário encontrado',
    notFoundSubtitle: 'Parece que o usuário que você está buscando não existe',
    cancel: 'Cancelar',
    create: 'Crie seu perfil',
    checklist: {
      avatar: 'Adicionar avatar',
      banner: 'Adicionar banner',
      links: 'Adicionar links sociais',
      bio: 'Adicionar bio',
    },
    bioCard: {
      title: 'Este é o seu perfil!',
      description:
        'Clique no botão de editar perfil para adicionar sua Bio, links sociais, banner, ou trocar sua foto de perfil.',
    },
    totalActivities: 'Total de Atividades',
    completedCourses: 'Cursos Concluídos',
  },
  klassView: {
    controls: {
      extraLesson: 'Aula Extra',
      lesson: 'Aula',
    },
    lessonNotFound: 'Não foi possível encontrar essa aula',
    instruction: {
      errorTextTitle: 'Vídeo não encontrado',
      errorTextSubTitle: 'O vídeo sobre a ferramenta não foi encontrado',
      vizualized: 'Visualizado',
      accessAndInstalation: 'Acesso e Instalação',
    },
    iconType: {
      access: 'Acesso a Ferramenta',
      activity: 'Atividades',
      teacherFeedback: 'Avaliação',
      studentFeedback: 'O que achou da aula?',
      attendance: 'Presença',
      challenge: 'Desafio',
      studentBook: 'Material',
      teacherBook: 'Material do Professor',
    },
    homework: {
      success: 'Resposta enviada',
      notDone: 'Não fez',
      waitingFeedback: 'Aguardando correção',
      pending: 'Pendente',
      revised: 'Corrigido',
      submit: 'Enviar',
      feedback: {
        feedback: 'Correção',
        addComment: 'Adicionar comentário para o aluno',
        editComment: 'Editar comentário para o aluno',
        cancel: 'Cancelar',
        grade: 'Nota',
        save: 'Enviar para o aluno',
        submittion: 'Envio',
        notDone: 'Não fez',
        hasChangesTooltip: 'Existem mudanças que precisam ser salvas',
        saved: 'Correção salva com sucesso',
      },
      error: {
        internalError:
          'Ocorreu um erro ao tentar carregar a lição. Entre em contato com o suporte',
      },
    },
    noHomework: 'Não há tarefa de casa nessa aula',
    challengeView: {
      errorTextSubTitle: 'Verifique se você está na página correta',
      errorTextTitle: 'Desafio não encontrado',
      title: 'Desafio',
      visualized: 'Visualizado',
    },
    initialView: 'Progresso da aula',
    noContent: 'Esta aula ainda não tem um conteúdo',
    material: 'Material',
    feedback: {
      feedbackInformation:
        'A avaliação será utilizada pelo time de pedagógico para melhorar o curso, não esqueça de pedir também para o aluno avaliar a aula',
      comment: 'Comentário (opcional)',
      content: 'Conteúdo',
      save: 'Salvar',
      saveError:
        'Ops, ocorreu um erro ao tentar salvar. Tente novamente mais tarde ou entre em contato com o time de suporte',
      alertWarning: 'Adicione uma nota antes de salvar',
      saveSuccess: 'Avaliação salva com sucesso!',
      contentAndActivity: 'Conteúdo da aula e atividades',
      teacherExplanation: 'Explicação do professor',
    },
    studentMaterial: 'Material do Aluno',
    teacherMaterial: 'Material do Professor',
    activity: 'Atividades',
    challenge: 'Desafio',
    noneMaterial: 'Nenhum material disponível no momento',
    toolAccess: 'Acesso a Ferramenta',
    goBack: 'Voltar',
    errorMessage: {
      homeworkPhrase: 'Não foi possível verificar e trazer a lição de casa',
      progressPhrase: 'Não foi possível carregar o seu progresso',
    },
  },
  manageRecessCalendars: {
    manageRecessCalendars: 'Gerenciamento do Calendário de Recesso',
    recessCalendar: 'Calendário de Recesso',
    recess: 'Recesso de',
    calendar: 'Calendário',
    edit: 'Editar',
    save: 'Salvar',
    cancel: 'Cancelar',
    dates: 'Datas',
    successMessage: 'Alterações salvas com sucesso',
    errorMessage: 'Ocorreu um erro ao salvaras informações',
  },
  calendarPage: {
    calendar: 'Calendário',
    recess: 'Recesso',
    classCalendar: 'Calendário de Aulas',
    seeMore: 'ver mais',
    nextClasses: 'Próximas Aulas',
    pastClasses: 'Aulas Passadas',
    studentCalendarPhrase: 'Aqui você pode ver a data das próximas aulas 🗓️',
    noTeacher: 'Sem professor',
  },
  calendar: {
    classCalendar: 'CALENDÁRIO DE AULAS',
    seeMore: 'ver mais',
    student: {
      seeLess: 'ver menos',
      calendar: 'Calendário',
      upcomingClasses: 'PRÓXIMAS AULAS',
    },
    admin: {
      calendar: 'Calendário de Aulas',
      selectDates: 'SELECIONE AS DATAS',
      nextClasses: 'PRÓXIMAS AULAS',
      allUnits: 'Todas as Unidades',
      filters: {
        allLessons: 'todas as aulas',
        completedLessons: 'aulas concluídas',
        plannedLessons: 'aulas programadas',
      },
      noContent: {
        allLessons: 'Nenhuma aula para essa data',
        completedLessons: 'Nenhuma aula concluída nessa data',
        plannedLessons: 'Nenhuma aula programada para essa data',
      },
      statisticCard: {
        classes: 'AULAS',
        frequency: 'PRESENÇA',
        weekFrequency: 'PRESENÇA ÚLTIMOS 7 DIAS',
        monthFrequency: 'PRESENÇA ÚLTIMOS 30 DIAS',
      },
      subtitleCard: {
        classesCompleted: 'aulas concluídas',
        studentFrequency: 'presença de alunos',
      },
      manageKlass: {
        klassLink: 'Acessar link da aula',
        noKlassLink: 'Essa aula não possui link',
        report: 'Relatório',
        delayedReport: 'Relatório Atrasado',
        noKlassTooltip: 'Tente acessar pela tabela de turmas',
        teacher: 'Professor(a)',
        edit: 'editar',
        finish: 'finalizar',
        alter: 'alterar',
        alterLessonDate: 'alterar somente essa aula',
        attendance: 'Presença:',
        progress: 'Progresso:',
        accessKlass: 'acessar aula',
        alterLink: 'alterar link',
        anticipate: 'antecipar aulas',
        postpone: 'adiar aulas',
        noStudentsInClass: 'Sem alunos, apenas o administrador pode editar',
        manageAttendance: {
          attendances: 'Presenças:',
          absences: 'Faltas:',
          reschedulings: 'Remarcações:',
        },
        updateKlassLink: {
          updateKlassLinkTitle: 'Deseja adicionar o link para as aulas online?',
          success: 'Link atualizado com sucesso',
          error: 'Erro ao atualizar o link',
          placeholder: 'Insira o link do Google Meet',
          confirm: 'Confirmar',
          cancel: 'Cancelar',
        },
        updateDateKlass: {
          insertValidTime: 'Insira um horário válido',
          updateDateKlassTitle:
            'Tem certeza que deseja alterar a data desta aula?',
          selectDate: 'Selecione a nova data para a próxima aula:',
          confirmDate: 'Deseja confirmar a alteração?',
          success: 'Data alterada com sucesso',
          error: 'Erro ao alterar a data',
          confirm: 'Confirmar',
          cancel: 'Cancelar',
          or: 'ou',
          postponeAnticipateWarning: 'Para alterar a data de várias aulas, use',
          timezoneWarning: 'As datas estão no fuso horário de {{timezone}}',
          noAvailableDatetime: 'Não há datas disponíveis',
          updateOtherLessonDate:
            'Para alterar a data/hora dessa aula altere a data/hora da aula anterior ou da próxima aula',
        },
        updateTeacher: {
          updateTeacherTitle:
            'Tem certeza que deseja substituir o professor para esta aula?',
          success: 'Substituição feita com sucesso',
          error: 'Erro ao substituir o professor',
          placeholder: 'Selecione outro professor',
          confirm: 'Confirmar',
          cancel: 'Cancelar',
        },
        anticipatePostpone: {
          warningResults:
            'Esta será a nova data para a próxima aula, e todas as aulas seguintes terão suas datas atualizadas:',
          success: 'Datas alteradas com sucesso',
          confirm: 'Confirmar',
          cancel: 'Cancelar',
          noDateMessage: 'Não existem datas para essa ação',
          postpone: {
            title: 'Tem certeza que deseja adiar esta e as próximas aulas?',
            warningMessage: 'Deseja confirmar o adiamento?',
            errorMessage: 'Erro ao adiar aula',
          },
          anticipate: {
            title: 'Tem certeza que deseja antecipar esta e as próximas aulas?',
            warningMessage: 'Deseja confirmar a antecipação?',
            errorMessage: 'Erro ao antecipar aula',
          },
        },
        doNotAnticipateTooltip: 'Não é possível essa aula',
        classStartsSoon: 'Aula começa em breve',
      },
    },
  },

  manageScheduledLessonReport: {
    notifyLateless: 'Notificar atraso',
    studentEntered: 'O aluno já entrou?',
    commentAlert:
      'A notificação foi encerrada porque o professor marcou que o aluno entrou na aula às {{hours}} horas',
    notifySucess: 'Os administradores foram notificados sobre o atraso',
    latelessUpdated: 'Atraso atualizado',
    reportFromAnotherTeacherMessage:
      'Esta aula está atribuída a outro professor, não é possível preencher este relatório.',
    contactAdminMessage:
      'Caso você tenha dado essa aula peça ao administrador da unidade para alterar o professor desta aula.',
    hasNoPermission: 'Você não tem permissão para realizar essa alteração',
    cantEditReport: 'Você não pode editar esse relatório',
    publisInstructions:
      'Essa aula ainda não foi publicada. Acesse a página de publicação e finalize todas as mudanças',
    goEditPage: 'Acessar página de edição',
    emptyContent: 'Insira um conteúdo para a tarefa',
    cashMessage:
      'Os alunos presentes na aula receberão Ctrl+Cash quando a aula for marcada como concluída',
    title: 'Relatório de Conclusão da Aula',
    day: 'Dia',
    placeholderLessonComment: 'Faça observações sobre {{student}} nesta aula',
    notDone: 'Não fez',
    students: 'Alunos',
    presence: 'Presença',
    challenge: 'Desafio',
    grades: 'Notas',
    hasSubmittion: 'Possui entrega',
    hasHomeworkActivity: 'Não enviar tarefa de casa',
    noStudents: 'Nenhum aluno encontrado!',

    seeHomework: 'Ver entrega do aluno',
    submittion: 'Envio',
    noSubmittion: 'O aluno não respondeu a atividade.',
    hasDoneDesmark:
      'Apenas um administrador pode desmarcar esta aula como concluída',
    addGrade: 'Adicionar Nota',
    missedPresenceStatus: 'F',
    activeStudent: 'Ativar',
    savedActiveStudent: 'Aluno ativado com sucesso',
    isLockedDesc: 'Apenas um administrador pode realizar esta ação',
    homeworkNotPublishedDesc:
      'Adicione uma tarefa aqui e seus alunos irão recebê-la',
    homeworkPublishedDesc:
      'Tarefa de casa publicada, aguarde pela resposta dos alunos',
    annotationsDesc: 'Anotações utilizadas pelo time pedagógico',
    nonMandatoryPhrase: 'Não é obrigatório preencher todos os campos.',
    notHomeworkPhrase: 'Você ainda não adicionou uma tarefa para esta aula',
    notCtrlCashPhrase:
      'Esta aula já foi marcada como concluída e você não pode alterar o Ctrl+Cash',
    pending: 'Pendente',
    published: 'Publicada',
    draft: 'Rascunho',
    publish: 'Publicar',
    allValues: 'Todos os valores',
    homework: 'Tarefa de Casa',
    behavior: 'Comportamento',
    addComments: 'Adicionar Comentários',
    practicalChallenge: 'Desafio Prático',
    effort: 'Esforço',
    cancel: 'Cancelar',
    save: 'Salvar',
    reset: 'Redefinir',
    hasDone: 'Concluído',
    gotoLesson: 'Ir para a aula',
    selectStudentPhrase: 'Selecione um aluno ao lado, clicando em seu nome.',
    contentGiven: 'Conteúdo Dado',
    contentGiveOptions: {
      [ContentGivenEnum.GAVE_100_PERCENT_AND_MORE]:
        'Consegui dar o conteúdo todo e coisas a mais',
      [ContentGivenEnum.GAVE_100_PERCENT]: 'Dei 100% do conteúdo',
      [ContentGivenEnum.GAVE_UP_T0_50_PERCENT]: 'Eu dei até 50% do conteúdo',
      [ContentGivenEnum.GAVE_BETWEEN_50_99_PERCENT]:
        'Eu dei entre 50-99% do conteúdo',
    },
    selectAnOptionPhrase: 'Selecione uma opção',
    classNotes: 'Anotações sobre a aula:',
    canWritePhrase:
      'Você pode anotar onde parou na aula, ou qualquer outro detalhe.',
    homeworkDisplayedPhrase: 'A tarefa de casa será exibida para os alunos',
    addHomeworkPhrase: 'Adicione uma tarefa de casa para os alunos.',
    classCompleted: 'Marcar aula como concluída',
    classNotCompleted: 'Marcar aula como não concluída',
    studentHasNoComments: 'Esse estudante não possui comentários',
    saved: 'Salvo',
    savedScheduledLesson: 'Alterações na lição salvas',
    savedHomework: 'Tarefa de casa salva',
    savedGivenContent: 'Conteúdo dado salvo',
    savedReport: 'Relatório salvo',
    selectAValidOptionPhrase: 'Selecione uma opção válida',
    requestMessages: {
      studentPresenceConfimed: 'Presença do aluno salva',
      studentPresenceRemoved: 'Presença do aluno removida',
      studentAbsenceConfirmed: 'Falta do aluno confirmada',
      genericError: 'Ocorreu um erro interno. Entre em contato com o suporte',
    },
    absences: 'Faltas',
    presences: 'Presenças',
    classCompletedButtonPhrase: 'Aula concluída',
    notPossibleToChangePhrase:
      'Não é possível alterar a aula depois de finalizada',
    annotationsPlaceholder:
      'Você pode anotar onde parou na aula, ou qualquer outro detalhe.',
    homeworkGradeMarker: {
      homeworkPreviousButton: 'Tarefas da aula anterior',
      saved: 'Salvo',
    },
    active: 'Ativos',
    inactive: 'Inativos',
    transfered: 'Transferido',
    noActiveStudentPhrase: 'Nenhum aluno ativo no dia desta aula \\ (•◡•) /',
    noReportForActiveStudent: '{{student}} não possui relatório nesta aula',
    noActiveStudentsInKlass: 'Não há alunos ativos nesta turma',
    anotherKlassTag: 'Outra turma',
    selectStudentReport:
      'Selecione um aluno na lista para acessar seu relatório nesta área',
  },
  teacherDashboard: {
    hello: 'Olá,',
    visitor: 'Visitante',
    absenceReports: {
      newer: 'mais recente',
      older: 'mais antigo',
      openReport: 'Abrir Relatório',
      markAsRead: 'Marcar como lido',
      absenceReports: 'Relatório de Faltas',
      noAbsenceReports: 'Nenhuma falta',
      markAllAsRead: 'Marcar todas como lidas',
      allReadSuccessfuly: 'Notificações lidas com sucesso',
      klassNotFound: 'Turma não encontrada',
      groups: {
        klass: 'turma',
        teacher: 'professor',
        all: 'geral',
      },
      totalItems_one: '{{count}} item',
      totalItems_other: '{{count}} itens',
      lastLessonsHelpMessage: {
        default: 'Presente na {{lessonName}}',
        MISSED: 'Faltou a {{lessonName}}',
      },
      seeMore: 'ver mais',
    },
    teacherKlasses: {
      yourClasses: 'Suas turmas',
      informations: 'Informações',
      progress: 'Acompanhamento',
      classes: 'Aulas',
      noStudents: 'Sem alunos',
      noClassesPhrase: 'Por enquanto não existem turmas',
      noStudentsToFollow: 'Sem alunos para acompanhar',
      sheet: 'Ficha da turma',
      studentSheet: 'Ficha do aluno',
    },
    teacherReports: {
      pendingReports: 'Relatórios Pendentes',
      noReportsPhrase: 'Não existem relatórios pendentes',
    },
    pendingHomeworks: {
      homeworkForCorrection: 'Tarefas para Correção',
      noHomeworks: 'Nenhuma tarefa de casa para correção',
      klassNotFound: 'Turma não encontrada.',
    },
  },
  adminDashboard: {
    totalStudents: 'Total de Alunos',
    totalKlasses: 'Total de Turmas',
    totalTeachers: 'Total de Professores',
    churnCount: 'Churn',
    attendanceCount: 'Presença',
    studentsPerKlass: 'Alunos por Turma',
    studentsPerTeacher: 'Alunos por Professor',
    lastUpdated: 'Última atualização',
    allUnits: 'Todas as unidades',
    pendingReports: 'Relatórios pendentes',
  },
  componentPagination: {
    next: 'Próximo',
    prev: 'Anterior',
    totalOf: {
      students: 'Total de alunos:',
      klasses: 'Total de classes:',
      teachers: 'Total de professores:',
      courses: 'Total de cursos:',
      team: 'Total de membros:',
      units: 'Total de unidades:',
    },
    of: 'de',
    pages: 'páginas',
    page: 'página',
  },

  editMaterialPage: {
    legalInformation: 'Informações legais',
    save: 'Salvar',
    editMaterial: 'Editar Material',
    chapterTitle: 'Título do Capítulo',
    content: 'Conteúdo',
    chapters: 'Capítulos',
    placeholderEditor:
      'H2: Para seções dentro dos capítulos, utilize ”Heading 2”',
    newChapterButton: 'Novo Capítulo',
    saveButton: 'Salvar',
    cancelButton: 'Cancelar',
    cantModify: 'Não permitido na edição limitada',
    createChapterMessage: 'Capítulo adicionado',
    saveChapterMessage: 'Capítulo salvo com sucesso',
    saveOrderMessage: 'Nova ordem salva com sucesso',
    deletedChapterMessage: 'Capítulo deletado com sucesso',
    oneChapterMessage: 'O livro deve conter pelo menos um capítulo',
    uploadMaterial: 'Upload Material',
    createMaterial: 'Criar Material',
    deleteMaterial: 'Excluir Material',
    bookIsBeingFormatedMessage: 'Aguarde enquanto o livro está sendo formatado',
    deleted: 'Material deletado com sucesso',
    updateSuccess: 'Alterações salvas com sucesso',
    autosave: 'Salvamento automático...',
    chapterFormattingFailureMessage:
      'Ocorreu um erro ao formatar este capítulo. Verifique se existem links, imagens corrompidas ou espaços vazios.',
  },

  rewardsView: {
    coinTooltip:
      'Você pode ganhar moedas ao completar atividades, material, desafios, entre outros',
    gemTooltip:
      'Você pode ganhar gemas ao completar 100% uma lição ou ao finalizar alguns projetos',
    xpTooltip:
      'Você pode ganhar xp fazendo atividades, ganhando like em projetos, ganhando preseças nas aulas entre outros',
    xp: '{{xp}} EXP',
    level: 'Nível {{lvl}}',
    errors: {
      genericError:
        'Não foi possível carregar as recompensas. Contacte o suporte',
    },
  },

  modalRewards: {
    attentionPhrase: 'Tem certeza que deseja adicionar ou sacar Ctrl+Cash?',
    rewardsButtons: {
      addCoins: 'adicionar moedas',
      withdrawCoins: 'sacar moedas',
    },
    inputPlaceholderPhrase: 'Digite qual foi a compra do aluno',
    inputPlaceholderValue: 'Valor',
    inputPlaceholderReason: 'Digite o motivo dessa transação',
    confirmWithdrawButton: 'Sacar',
    confirmDepositButton: 'Depositar',
    cancelButton: 'Cancelar',
    select: {
      book: 'Livro',
      activity: 'Atividade',
      challenge: 'Desafio',
      homework: 'Lição de casa',
      videoAccess: 'Acesso ao vídeo',
      programAccess: 'Acesso ao programa',
      lesson: 'Lição',
      minorProject: 'Projeto menor',
      majorProject: 'Projeto maior',
      behavior: 'Comportamento',
      effort: 'Esforço',
      presence: 'Presença',
      selectTheReason: 'Selecione o motivo da recompensa',
    },
    messages: {
      transactionSuccess: 'Transação feita com sucesso',
      transactionWarning: 'Preencha todos os campos',
      genericError: 'Ocorreu um erro ao buscar as recompensas',
    },
  },

  modalHomework: {
    addHomeworkAssessment: 'Adicione avaliação da tarefa de casa',
    notDone: 'Não fez',
    confirmButton: 'Confirmar',
    cancelButton: 'Cancelar',
  },

  modalEmptyActivity: {
    attentionPhrase: 'Atenção! Este curso possui atividade(s) vazias',
    deletePhrase:
      'Deseja excluir a(s) atividade(s) antes de publicá-lo ou continuar editando?',
    confirmButton: 'Deletar e Publicar',
    cancelButton: 'Cancelar',
    lesson: 'Aula',
    extraLesson: 'Aula Extra',
  },

  userForm: {
    firstName: 'Nome',
    lastName: 'Sobrenome',
    email: 'Email',
    dateOfBirth: 'Nascimento',
    username: 'Usuário',
    state: 'Estado',
    city: 'Cidade',
    school: 'Escola',
    unit: 'Unidade',
    phoneNumber: 'Telefone',
    klass: 'Turma',
    userType: 'Papel',
    status: 'Status',
    cancel: 'Cancelar',
    save: 'Salvar',
    saved: 'Salvo',
    userStatus: userStatus,
    errors: {
      required: 'Esse campo é requerido',
    },
  },
  projectPage: {
    projects: 'Projetos',
    noProjectsFound: 'Nenhum projeto encontrado',
    filters: {
      allProjects: 'Todos',
      myProjects: 'Minha Autoria',
      myClassProjects: 'Minha turma',
      myCourseProjects: 'Meu curso',
    },
    projectInfoModal: {
      cancel: 'Cancelar',
      description: 'Descrição',
      projectUrl: 'Link do projeto',
      publishProject: 'Publicar projeto',
      selectTool: 'Selecionar ferramenta',
      title: 'Título',
      publishNewProject: 'Publicar novo projeto',
      projectUrlPlaceholder: 'Insira o link do seu projeto',
      titlePlaceholder: 'Insira o título do projeto',
      updateProject: 'Atualizar projeto',
      imageUploadFail: 'Ocorreu um erro ao fazer o upload de imagem:',
      isStudentProject: 'Está publicando projeto de um aluno?',
      studentSelectPlaceholder: 'Selecionar aluno',
      invalidLink: 'Link inválido',
      projectUrlRequired: 'Link do projeto é obrigatório',
      titleRequired: 'Título é obrigatório',
      toolRequired: 'Ferramenta é obrigatória',
      imageRequired: 'É obrigatório adicionar uma capa ao projeto',
      successAddedMessage: 'Projeto adicionado com sucesso',
    },
    allTools: 'Todas ferramentas',
    addProject: 'Adicionar Projeto',
    search: 'Pesquisar',
    all: 'Todos',
    highlights: 'Destaques',
    recents: 'Novos',
  },
  projectView: {
    description: 'Descrição',
    projects: 'Projetos',
    checkInfo: 'Confira se você está na rota correta',
    projectNotFound: 'Projeto não encontrado',
    editProject: 'Editar',
    deleteProject: 'Excluir projeto',
  },
  inventory: {
    backpack: 'Mochila',
    save: 'Salvar',
    alterFail: 'Houve um problema ao salvar o avatar',
    alterSuccess: 'Alterações salvas com sucesso',
    removeItem: 'Remover item',
    goToShop: 'Ir à loja',
    purchaseSuccess: 'Compra realizada com sucesso',
    purchaseError: 'Ocorreu um erro ao tentar comprar o item',
    inventory: 'Inventário',
    shop: 'Loja',
    notEnoughCash: 'Sem fundos o suficiente',
    confirmPurchase: 'Tem certeza de que deseja comprar o item abaixo?',
    equipItem: 'Equipar Item',
    confirm: 'Confirmar',
    cancel: 'Cancelar',
    noItems: 'Nenhum item disponível',
    itemCategory: {
      base: 'Base',
      top: 'Cima',
      bottom: 'Baixo',
      accessories: 'Acessórios',
      equipment: 'Equipamento',
    },
    inventoryItems: {
      hair: 'Cabelo',
      base: 'Corpo',
      hat: 'Chapéu',
      head: 'Cabeça',
      face: 'Rosto',
      topFaceAcessory: 'Olhos',
      bottomFaceAcessory: 'Boca',
      neckAcessory: 'Pescoço',
      shirt: 'Camisa',
      coat: 'Casaco',
      pants: 'Calça',
      shoe: 'Sapato',
      hand: 'Mão',
      leftHandAcessory: 'Mão Esquerda',
      rightHandAcessory: 'Mão Direita',
    },
  },
  klassProgress: {
    myLessons: 'Minhas Aulas',
    lessonProgressList: {
      didNotFindLesson:
        'Não foi possível encontrar o progresso da lição. Fale com o suporte.',
      generalError:
        'Ocorreu um erro ao tentar carregar o progresso da aula. Fale com o suporte.',
      noLessons: 'Parece que não existem lições. Fale com o suporte.',
    },
  },

  nextLessonView: {
    noNextLesson: 'Parece que você está sem uma próxima aula agendada.',
    noTeacher: 'Professor não selecionado',
  },

  editActivity: {
    title: 'Enunciado:',
    subtitle: 'Subtítulo:',
    fields: {
      errors: {
        noTitle: 'A questão precisa de um enunciado',
      },
    },
  },

  klassInput: {
    placeholder: 'Selecione uma turma',
    warnings: {
      noResults: 'Sem resultados',
      noStudentsToFollow: 'Sem alunos para acompanhar',
    },
    errors: {
      internalError: 'Ocorreu um erro interno. Entre em contato com o suporte',
    },
  },

  rewardInput: {
    placeholder: 'Valor',
  },

  lessonForm: {
    name: 'Título da Aula',
    description: 'Descrição da Aula',
    message: 'Mensagem Motivacional',
    toolUrl: 'Link de acesso ao programa',
    links: 'Links',
    instructionsUrl: 'Instalar/acessar o programa',
    toolLinkMessage: 'Exemplo: https://scratch.mit.edu/',
    videoInstallMessage: 'Exemplo: https://www.youtube.com/watch?v=PWGX3QfAwCA',
    additHere: 'adiciona-lá aqui',
    saved: 'Alterações salvas com sucesso',
    tools: 'Ferramentas',
    topics: 'Tópicos',
    toolOption: 'Opção de ferramenta',
    inBank: 'Adicionar ao Banco de Aulas',
    isPublicInBank: 'Público',
    normal: 'Normal',
    free: 'Livre',
    freeExplain:
      'Quando o conteúdo da aula é fora do currículo, aumentando a quantidade de aulas total do curso. Recomendado para eventos ou feriados.',
    normalExplain:
      'Quando precisa substituir uma ferramenta ou passar mais tempo para terminar um projeto. Você pode desativar outra aula para manter o total de 18 aulas.',
    addAuxiliaryLinks: 'Adicionar links',
    warnings: {
      required: 'Esse campo é requerido',
      validateLink: 'Digite um link válido',
      mainTool: 'Selecione uma ferramenta principal',
    },
    toolPhrase: 'Caso a ferramenta não esteja na lista, pode',
    addToolTitle: 'Adicionar Ferramenta',
  },

  enrollmentStatus: {
    ACTIVE: 'Ativo',

    AWAITING_CLASS: 'Aguardando turma',
    AWAITING_PAYMENT: 'Aguardando pagamento',
    AWAITING_PAYMENT_admin: 'Aguardando pagamento',
    AWAITING_PAYMENT_teacher: 'Matrícula em processamento',
    COURSE_TRANSFERRED: 'Transferido de curso',
    CLASS_TRANSFERRED: 'Transferido de turma',
    CONCLUDED: 'Formado',
    CANCELED: 'Cancelado',
    TRANSFERRED_TO_CLASS: 'Transferido para essa turma',
    TRANSFERRED_FROM_CLASS: 'Transferido para outra turma',
    ACTIVE_IN_THIS_LESSON: 'Cancelado em aulas futuras',
  },

  tagInput: {
    tool: 'Ferramentas',
    mainToolBanner: 'Definir como capa',
    editTag: 'Editar tag',
    banner: 'Capa',
  },

  tagForm: {
    name: 'Nome',
    image: 'Imagem',
    link: 'Link de acesso',
    color: 'Cor',
    clear: 'Limpar',
    confirm: 'Confirmar',
    warnings: {
      required: 'Esse campo é requerido',
      validateLink: 'Digite um link válido',
    },
    editSaved: 'Alterações salvas com sucesso',
    addSaved: 'Tag criada com sucesso',
  },

  colorInput: {
    color: 'Cor',
    clear: 'Limpar',
    confirm: 'Confirmar',
    warnings: {
      validateHexadecimalCode: 'Digite uma cor válida',
    },
  },

  listLessons: {
    successUpdateOrder: 'Ordem de aulas atualizada',
    warnings: {
      noCourse:
        'Não foi posssível carregar o curso. Tente novamente mais tarde ou entre em contato com o time de suporte',
    },
  },

  coursesTab: {
    warnings: {
      noCoursesPath: 'Nenhum curso encontrado',
    },
  },

  klassStatus,

  userTypes: {
    [UserTypeEnum.STUDENT]: 'Estudante',
    [UserTypeEnum.TEACHER]: 'Professor',
    [UserTypeEnum.SUPER_ADMIN]: 'Super Administrador',
    [UserTypeEnum.UNIT_ADMIN]: 'Administrador da Unidade',
    [UserTypeEnum.PARENT]: 'Parente',
  },

  courseType,

  userStatus: {
    REGISTRED: 'Registrado',
    ACTIVE_ENROLLMENT: 'Matrícula Ativa',
    INACTIVE: 'Inativo',
    WAITING_ENROLLMENT: 'Aguardando Matrícula',
  },
  ckeditor: {
    placeholder: 'Escreva aqui...',
  },

  pedagogicalSheet: { ...sheet, title: 'Ficha Pedagógica' },

  scrollControl: {
    upButtonTooltip: 'Ir ao topo',
    downButtonTooltip: 'Ir ap final',
  },

  commentAssessmentForm,

  studentBehaviorRating: {
    1: 'Totalmente desinteressado (Faz atividades diferente do que é proposto pelo curso, ou não faz)',
    2: 'Introvertido, porém com breves momentos de socialização',
    3: 'Tem foco, Consegue manter a atenção durante toda a aula',
    4: 'Organizado porém demonstra um certo desconforto quanto a desorganização alheia',
    5: 'Totalmente interessado (Faz atividades e desafios propostos pelo curso)',
  },

  klassSheet: { ...sheet, title: 'Ficha da Turma' },

  assessmentHeader: sheet,

  behaviorAssessmentForm,

  presenceStatus,

  klassEnergyRating: {
    1: 'Turma quieta',
    2: 'Turma normal',
    3: 'Turma enérgica',
  },

  klassSpeedRating: {
    1: 'Turma lenta',
    2: 'Turma normal',
    3: 'Turma rápida',
  },

  klassMaturityRating: {
    1: 'Turma infantil',
    2: 'Turma normal para a idade',
    3: 'Turma madura',
  },

  courseCategory,

  buttonInfiniteList: {
    loadMore: 'Carregar mais',
  },

  consentModal: {
    message:
      'Usamos cookies e local storage para melhorar sua experiência de navegação.',
    accept: 'Aceitar',
  },
  topics: {
    pageTitle: 'Tópicos',
    topbar: {
      new: 'Criar novo',
      newArea: 'Nova área',
      newTag: 'Nova tag',
      save: 'Salvar',
      searchPlaceholder: 'Pesquisar por áreas',
    },
    tree: {
      createNew: 'Crie uma área',
    },
    workspace: {
      selectArea: 'Selecione uma área',
    },
    moveTo: {
      cancel: 'Cancelar',
      move: 'Mover',
      placeholder: 'Pesquisar área',
      currentArea: 'área atual',
      label: 'Mover para',
      transformRoot: 'transformar em tópico pai',
    },
    input: {
      searchForTopics: 'Pesquise por tópicos',
      label: 'Tópicos',
    },
    tooltip: {
      move: 'Mover área',
      renaming: 'Renomear',
      delete: 'Deletar',

      area: {
        title: 'Área',
        desc: 'Áreas podem conter tags ou outras áreas',
      },
      tag: {
        title: 'Tag',
        desc: 'Tags são marcadores de conteúdo que adicionam dificuldade e nível em uma área',
      },
    },
    tags: {
      description: {
        label: 'Descrição',
        required: 'A descrição é obrigatória',
      },
      difficulty: {
        label: 'Dificuldade',
        values: {
          ADVANCED: 'Avançado',
          INTERMEDIATE: 'Intermediário',
          BEGINNER: 'Iniciante',
        },
      },
      level: {
        label: 'Nível',
        values: { 1: 'I', 2: 'II', 3: 'III', 4: 'IV', 5: 'V' },
      },
      add: 'Adicionar',
      cancel: 'Cancelar',
      addDifficulty: 'Adicionar dificuldade',
    },
  },
  wellcome: {
    hello: 'Olá, {{firstName}} 👋',
    student:
      'Bem vindo de volta, tripulante! Pronto para continuar sua jornada? 🚀',
  },
  warning: {
    addAndEditClass:
      'Atenção! Não é possível adicionar ou editar turmas pelo Portal, somente pela Loja',
  },

  weekday,

  klassType: {
    REGULAR: 'Turma Regular',
    CONECTA: 'Turma Conecta',
    SCHOOL: 'Turma na Escola',
  },

  hideComponent: {
    options: {
      hiddenSection: 'Ocultar seção',
    },
  },

  hiddenComponents: {
    title: 'Seções ocultas',
    activate: 'Ativar',
  },

  modalHiddenComponents: {
    header: {
      title: 'Você ocultou uma seção da sua dashboard!',
      paragraph:
        'Não se preocupe, se quiser ver ele novamente, é só ativá-lo na lista de seções ocultas.',
    },
    footer: {
      paragraph: 'Você consegue encontrar essa lista no final da sua dashboard',
      button: 'Entendi!',
    },
  },
  contacts: {
    noContacts: 'O usuário não possui telefones de contato',
    student: 'Aluno',
    sendMessage: 'Enviar mensagem',
  },
  alerts: {
    title: 'Notificações',
    page: {
      inbox: 'Caixa de Entrada',
      filters: 'Filtros',
    },
    list: {
      empty: 'Nenhuma notificação',
    },
    buttons: {
      contact: 'Contatos',
      comment: {
        add: 'Adicionar comentário',
        edit: 'Editar comentário',
      },
      fullScreen: 'Tela cheia',
      copyMessage: 'Copiar mensagem',
    },
    inputs: {
      commentPlaceholder: 'Digite seu comentário',
    },
    filters: {
      status: {
        all: 'todos',
        yesterday: 'ontem',
        today: 'hoje',
        chooseDate: 'escolher data',
      },
      newest: 'mais recentes',
      oldest: 'mais antigas',
      type: 'Tipo',
      unit: 'Unidade',
      klass: 'Turma',
      onlyPendings: 'Apenas pendentes',
      teacher: 'Professor',
    },
    verbs: {
      [AlertTypeEnum.LATENESS]: 'ainda não entrou na aula',
      [AlertTypeEnum.ABSENCE]: 'faltou em uma aula',
    },
    types: {
      [AlertTypeEnum.LATENESS]: 'atraso',
      [AlertTypeEnum.ABSENCE]: 'falta',
    },
    messages: {
      latelessBody:
        'Notamos que o (a) {{studentName}} ainda não entrou na {{lessonName}} de hoje que começou às {{lessonDate}}.',
      absenceBody:
        'Notamos que o (a) {{studentName}} faltou na {{lessonName}} de hoje que começou às {{lessonDate}}.',
      link: 'Você pode acessar a aula através desse link: {{link}}.',
      simpleBody:
        'Notamos que o (a) {{studentName}} não entrou na aula de hoje.',
      presentation: 'Olá, tudo bem? Aqui é o (a) {{sender}}.',
      footer: 'Obrigado pela atenção!',
      question: 'Pode nos informar o motivo?',
    },
  },
  hiddenComponent: {
    todayLessons: 'Aulas de hoje',
    teachingWeek: 'Turmas',
  },

  klassesFilters: {
    courseCategory: 'Modalidade',
    courseType: 'Cursos',
    dayOfWeek: 'Dia',
    otherFilters: 'Outros filtros',
  },

  weekdayKlassCell: {
    termination: 'Término',
  },

  nextLessonKlass: {
    warning: 'Sem aulas ativas.',
  },

  klassPopup: {
    seeMore: 'Ver mais',
    copyNames: 'Copiar todos os nomes',
    copyEmails: 'Copiar todos os e-mails',
  },

  useKlassOption: {
    info: 'Informações',
    lessons: 'Lista de aulas',
    progress: 'Progresso',
    sheet: 'Ficha da turma',
    members: 'Membros da turma',
  },

  updates: {
    notifications: {
      description:
        'Agora você pode receber e notificações administrativas sobre os alunos e as turmas e rapidamente entrar em contato com os responsáveis. Por enquanto, temos notificações de faltas e atrasos dos alunos, com mais melhorias prestes à serem adicionadas!',
      title: 'Notificações',
    },
    notifyLatelessButton: {
      description:
        'Agora você pode notificar a secretaria diretamente que o aluno ainda não chegou',
      title: 'Atraso: Relatório de Conclusão da Aula',
      secondDescriptionState:
        'Se o aluno entrar você pode encerrar a notificação para evitar que os responsáveis sejam cobrados',
      lastDescriptionState:
        'Comentários que a secretaria fizer também ficam disponíveis para você ler',
    },
    teachingWeek: {
      description:
        'Agora você pode visualizar o calendário das suas turmas de uma forma melhor. As informações que você já tinha antes agora estão disponíveis no "VER MAIS" de cada turma',
      title: 'O calendário da turma mudou!',
    },
    todayLessons: {
      description:
        'Agora você pode visualizar quais aulas estão programadas para o dia de hoje. Suas aulas de hoje podem ser filtradas por passadas, em andamento e futuras',
      title: 'Você pode ver as aulas de hoje!',
    },
    notificationsPage: {
      description:
        'Agora as notificações possuem uma página para maior visiblilidade. Você pode acessa-la pelo menu "Notificações"',
      title: 'Página de notificações',
    },
    gotIt: 'Entendi',
  },

  timeframeFilter: {
    ALL: 'Todas',
    ONGOING: 'Em andamento',
    PAST: 'Passadas',
    UPCOMING: 'Futuras',
  },

  relationships: {
    FATHER: 'Pai',
    MOTHER: 'Mãe',
    BROTHER: 'Irmão',
    SISTER: 'Irmã',
    UNCLE: 'Tio',
    AUNT: 'Tia',
    GRANDFATHER: 'Avô',
    GRANDMOTHER: 'Avó',
    OTHER: 'Responsável',
  },

  filters: {
    ...courseCategory,
    ...courseType,
    ...weekday,
    ...klassStatus,
    startSoon: 'Início próximo',
    endSoon: 'Término próximo',
    hasActiveLessons: 'Possui aulas ativas',
  },

  klassGroupButton: {
    tooltip: 'A turma não possui um link para o grupo',
    textButton: 'Grupo da turma',
  },
};

export default pt_BR;
