import requester from '@/axios';
import JsonApiResponse from '@/models/JsonApiResponse';
import { BaseFilters } from '@/models/Service';
import { VacationNotice } from '@/models/VacationNotice';

export type ListVacationsFilters = BaseFilters & {
  klassId?: number;
  date?: string;
  userId?: number;
};

const baseUrl = 'students/vacation-notices';

export async function listVacationNotices(params: ListVacationsFilters = {}) {
  const { data } = await requester().get<JsonApiResponse<VacationNotice>>(
    `${baseUrl}/`,
    {
      params,
    },
  );

  return data;
}

export async function getVacationNotice(vacationNoticeId: number) {
  const { data } = await requester().get<VacationNotice>(
    `${baseUrl}/${vacationNoticeId}/`,
  );

  return data;
}

export async function createVacationNotice(body: Partial<VacationNotice>) {
  const { data } = await requester().post<VacationNotice>(`${baseUrl}/`, body);

  return data;
}

export async function updateVacationNotice(
  vacationNoticeId: number,
  body: Partial<VacationNotice>,
) {
  const { data } = await requester().patch<VacationNotice>(
    `${baseUrl}/${vacationNoticeId}/`,
    body,
  );

  return data;
}

export async function removeVacationNotice(vacationNoticeId: number) {
  const { data } = await requester().delete<VacationNotice>(
    `${baseUrl}/${vacationNoticeId}/`,
  );

  return data;
}
