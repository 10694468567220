import { ListKlassesFilters } from '@/data/services/klassServices';
import { createSearchParams, URLSearchParamsInit } from 'react-router-dom';

type QueryRoute = URLSearchParamsInit & ListKlassesFilters;

const ADMIN_BASE = '/admin';
const CLASS_BASE = '/class';
const CLASSES_BASE = '/classes';
const STUDENTS_BASE = '/students';
const TEACHERS_BASE = '/teachers';
const TEAM_BASE = '/team';
const UNITS_BASE = '/units';
const COURSES_BASE = '/courses';
const LESSON_BASE = '/lesson';
const LESSONS_BASE = '/lessons';
const BOOK_BASE = '/book';
const CHAPTER_BASE = '/chapter';
const ACTIVITIES_BASE = '/activities';
const PROFILE_BASE = '/profile';
const LOGIN_BASE = '/login';
const STUDENT_LESSON_FEEDBACK_BASE = '/student-lesson-feedback';
const PROJECTS_BASE = '/projects';
const TEACHER_BASE = '/teacher';
const STUDENT_HISTORY_BASE = '/student-history';
const CALENDAR_BASE = '/calendar';
const NOTIFICATIONS_BASE = '/notifications';

type CourseLessonRoute = {
  slug: string;
  lessonId: string | number;
  klassId?: string | number;
};

export const ROUTES = {
  ADMIN: {
    BASE: ADMIN_BASE,
    CLASSES: {
      BASE: `${ADMIN_BASE}${CLASSES_BASE}` as const,
      INFO: (query?: QueryRoute) =>
        `${ROUTES.ADMIN.CLASSES.BASE}/info/${createQuery(query)}`,
      LESSONS: (query?: QueryRoute) =>
        `${ROUTES.ADMIN.CLASSES.BASE}${ROUTES.LESSONS.BASE}/${createQuery(
          query,
        )}`,
      SHEET: (query?: QueryRoute) =>
        `${ROUTES.ADMIN.CLASSES.BASE}/sheet/${createQuery(query)}`,
      MEMBERS: (query?: QueryRoute) =>
        `${ROUTES.ADMIN.CLASSES.BASE}/members/${createQuery(query)}`,
      ACTION: (actionId: string, query?: QueryRoute) =>
        `${ROUTES.ADMIN.CLASSES.BASE}/${actionId}/${createQuery(query)}`,
    },
    STUDENTS: {
      BASE: `${ADMIN_BASE}${STUDENTS_BASE}` as const,
      INFO: (query?: QueryRoute) =>
        `${ROUTES.ADMIN.STUDENTS.BASE}/info/${createQuery(query)}`,
      SHEET: (query?: QueryRoute) =>
        `${ROUTES.ADMIN.STUDENTS.BASE}/sheet/${createQuery(query)}`,
      HISTORIC: (query?: QueryRoute) =>
        `${ROUTES.ADMIN.STUDENTS.BASE}/historic/${createQuery(query)}`,
      ACTION: (actionId: string, query?: QueryRoute) =>
        `${ROUTES.ADMIN.STUDENTS.BASE}/${actionId}/${createQuery(query)}`,
    },
    TEACHERS: {
      BASE: `${ADMIN_BASE}${TEACHERS_BASE}` as const,
      INFO: (query?: QueryRoute) =>
        `${ROUTES.ADMIN.TEACHERS.BASE}/info/${createQuery(query)}`,
      LESSONS: (query?: QueryRoute) =>
        `${ROUTES.ADMIN.TEACHERS.BASE}${ROUTES.LESSONS.BASE}/${createQuery(
          query,
        )}`,
      ACTION: (actionId: string, query?: QueryRoute) =>
        `${ROUTES.ADMIN.TEACHERS.BASE}/${actionId}/${createQuery(query)}`,
    },
    TEAM: {
      BASE: `${ADMIN_BASE}${TEAM_BASE}` as const,
      INFO: (query?: QueryRoute) =>
        `${ROUTES.ADMIN.TEAM.BASE}/info/${createQuery(query)}`,
      ACTION: (actionId: string, query?: QueryRoute) =>
        `${ROUTES.ADMIN.TEAM.BASE}/${actionId}/${createQuery(query)}`,
    },
    UNITS: {
      BASE: `${ADMIN_BASE}${UNITS_BASE}` as const,
      INFO: (query?: QueryRoute) =>
        `${ROUTES.ADMIN.UNITS.BASE}/info/${createQuery(query)}`,
      RECESS_CALENDAR: (unitId: number | string, query?: QueryRoute) =>
        `${ROUTES.ADMIN.UNITS.BASE}/${unitId}/recess-calendar/${createQuery(
          query,
        )}`,
      ACTION: (actionId: string, query?: QueryRoute) =>
        `${ROUTES.ADMIN.UNITS.BASE}/${actionId}/${createQuery(query)}`,
    },
    COURSES: {
      BASE: `${ADMIN_BASE}${COURSES_BASE}` as const,
      INFO: (query?: QueryRoute) =>
        `${ROUTES.ADMIN.CLASSES.BASE}/info/${createQuery(query)}`,
      ACTION: (actionId: string, query?: QueryRoute) =>
        `${ROUTES.ADMIN.COURSES.BASE}/${actionId}/${createQuery(query)}`,
      VERSIONING: (slug: string) =>
        `${ROUTES.ADMIN.COURSES.BASE}/${slug}${ROUTES.LESSONS.BASE}/versioning`,
      EDITING: (slug: string) =>
        `${ROUTES.ADMIN.COURSES.BASE}/${slug}${ROUTES.LESSONS.BASE}/editing`,
      DRAFT: (slug: string) =>
        `${ROUTES.ADMIN.COURSES.BASE}/${slug}${ROUTES.LESSONS.BASE}/draft`,
    },
    CALENDARS: {
      BASE: '/calendars' as const,
    },
  },

  CLASS: {
    BASE: CLASS_BASE,
    PROGRESS: (klassId: string | number) =>
      `${ROUTES.CLASS.BASE}/${klassId}/progress`,
    LESSON_REPORT: (
      klassId: string | number,
      scheduledLessonId: string | number,
    ) => `${ROUTES.CLASS.BASE}/${klassId}/lesson-report/${scheduledLessonId}`,
  },
  COURSES: {
    BASE: '/courses' as const,
    INFO: (slug: string) => `${ROUTES.COURSES.BASE}/${slug}`,
    LESSON: {
      INFO: (
        slug: string,
        lessonId: string | number,
        klassId?: string | number,
      ) =>
        `${ROUTES.COURSES.BASE}/${slug}${ROUTES.LESSON.BASE}/${lessonId}${
          klassId ? `${ROUTES.CLASS.BASE}/${klassId}` : ''
        }`,
      BOOK: {
        CHAPTER: ({
          slug,
          lessonId,
          klassId,
          chapterOrder,
        }: CourseLessonRoute & { chapterOrder?: string | number }) =>
          `${ROUTES.COURSES.LESSON.INFO(slug, lessonId, klassId)}${
            ROUTES.BOOK.BASE
          }${ROUTES.CHAPTER.BASE}/${chapterOrder}`,
      },
      TEACHER_BOOK: {
        CHAPTER: ({
          slug,
          lessonId,
          klassId,
          chapterOrder,
        }: CourseLessonRoute & { chapterOrder?: string | number }) =>
          `${ROUTES.COURSES.LESSON.INFO(slug, lessonId, klassId)}/t${
            ROUTES.BOOK.BASE
          }${ROUTES.CHAPTER.BASE}/${chapterOrder}`,
      },

      ACTIVITIES: ({ slug, lessonId, klassId }: CourseLessonRoute) =>
        `${ROUTES.COURSES.LESSON.INFO(slug, lessonId, klassId)}${
          ROUTES.ACTIVITIES.BASE
        }`,
      CHALLENGE: ({ slug, lessonId, klassId }: CourseLessonRoute) =>
        `${ROUTES.COURSES.LESSON.INFO(slug, lessonId, klassId)}/challenge`,
      HOMEWORK: ({ slug, lessonId, klassId }: CourseLessonRoute) =>
        `${ROUTES.COURSES.LESSON.INFO(
          slug,
          lessonId,
          klassId,
        )}${ACTIVITIES_BASE}/homework`,
      HELP: ({ slug, lessonId, klassId }: CourseLessonRoute) =>
        `${ROUTES.COURSES.LESSON.INFO(slug, lessonId, klassId)}/help`,
    },
  },
  LESSONS: {
    BASE: LESSONS_BASE,
  },
  LESSON: {
    BASE: LESSON_BASE,
  },
  NOTIFICATIONS: {
    BASE: NOTIFICATIONS_BASE,
  },
  BOOK: {
    BASE: BOOK_BASE,
  },
  CHAPTER: {
    BASE: CHAPTER_BASE,
  },
  ACTIVITIES: {
    BASE: ACTIVITIES_BASE,
  },
  PROFILE: {
    BASE: PROFILE_BASE,
    INFO: (username: string) => `${ROUTES.PROFILE.BASE}/${username}`,
  },
  TEACHER: {
    BASE: TEACHER_BASE,
  },
  PROJECTS: {
    BASE: PROJECTS_BASE,
    INFO: (projectId: number | string) =>
      `${ROUTES.PROJECTS.BASE}/${projectId}`,
  },
  STUDENT_HISTORY: {
    BASE: STUDENT_HISTORY_BASE,
    INFO: (studentId: number | string) =>
      `${ROUTES.STUDENT_HISTORY.BASE}/${studentId}`,
  },
  FORBIDDEN: {
    BASE: '/forbidden' as const,
  },
  LOGIN: {
    BASE: LOGIN_BASE,
    RESET_PASSWORD: (uid: string, token: string) =>
      `${ROUTES.LOGIN.BASE}/reset-password/${uid}/u/${token}`,
    PASSWORD_RESET: (urlToken: string) =>
      `${ROUTES.LOGIN.BASE}/password-reset/new-password/${urlToken}`,
    PASSWORD_CHANGED: `${LOGIN_BASE}/password-changed`,
  },
  STUDENT_LESSON_FEEDBACK: {
    BASE: STUDENT_LESSON_FEEDBACK_BASE,
  },
  CALENDAR: {
    BASE: CALENDAR_BASE,
  },
};

const createQuery = (query?: QueryRoute) =>
  query ? `?${createSearchParams(query)}` : '';
